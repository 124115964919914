<template>
    <div class="container">
        <div class="row justify-content-md-center py-5 my-5">
            <div class="col-12 col-sm-8">
                <h1>404 Page not found</h1>
                <p>404 Page not found</p>
                <h3>Oops, vypadá to, že stránka, kterou hledáte, neexistuje.</h3>
                <p>Oops, it looks like the page you're looking for doesn't exist.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PageNotFound"
    }
</script>

<style scoped>

</style>