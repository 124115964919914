export const config = {
    gtm: 'GTM-K3B5HZ3'
};

export const api = {
    urlDev: "",
    urlProduction: "https://energycloud.solarnireseni.cz/api/v1/lead",
    token: "oTF9O0Y3xHWk3Zi3SU2BbbnVMGar0pE6",
};


export const questions = [
    {
        infoBefore: '',
        infoAfter: '',
        fields: [
            {
                type: "magicSelect",
                label: "Typ objektu",
                model: "question1",
                cols: 3,
                values: [
                    {name: "Domácnost",value: 'dům', img: require('@/assets/img/house.svg')},
                    {name:"Firma", value: 'firma', img: require('@/assets/img/company.svg')},
                    {name: "Domácnost + Firma",value: 'firma+dům', img: require('@/assets/img/house_company.svg')},
                ],
                required: true,
                validator: 'string',
            },
        ]
    },
    {
        fields: [
            {
                type: "magicSelect",
                label: "Jaká je Vaše výše měsíčních záloh na energie?",
                model: "question2",
                values: [
                    "Do 1000 Kč",
                    "1001 - 2000 Kč",
                    "2001 - 3000 Kč",
                    "Více než 3000 Kč"
                ],
                required: true,
                validator: 'string',
            },
        ]
    },
    {
        fields: [
            {
                type: "magicSelect",
                label: "O jaký typ akumulace máte zájem?",
                model: "question3",
                cols: 4,
                values: [
                    {name: "Baterie",value: 'baterie', img: require('@/assets/img/722.png')},
                    {name:"Ohřev vody", value: 'voda', img: require('@/assets/img/33271.png')},
                    {name: "Kombinace",value: 'baterie+voda', img: require('@/assets/img/batheat.png')},
                    {name: "Nevím",value: 'nevim', img: require('@/assets/img/dontknow.png')},
                ],
                required: true,
                validator: 'string',
            },
        ]
    },
    {
        infoAfter: "Prosím uveďte PSČ nebo adresu pro maximální přesnosti cenové nabídky.",
        fields: [
            {
                type: "input",
                inputType: "text",
                label: "PSČ nebo adresa odběrného místa",
                model: "locality",
                min: 2,
                max: 100,
                required: true,
                validator: 'string',
            },
        ]
    },
    {
        infoBefore: "Už jen pár drobností. Vyplňte pár posledních údajů a pokračujte.",
        fields: [
            {
                type: "input",
                inputType: "email",
                label: "E-mailová adresa:",
                model: "email",
                required: true,
                validator: 'email',
            },
        ]
    },
    {
        fields: [
            {
                type: "input",
                inputType: "text",
                label: "Vaše jméno:",
                model: "name",
                min: 3,
                max: 100,
                required: true,
                validator: 'string',
            },
        ]
    },
    {
        fields: [
            {
                type: "input",
                inputType: "text",
                label: "Telefonní číslo:",
                model: "phone",
                min: 9,
                max: 16,
                required: true,
                validator: 'string',
            },
        ]
    }
];