<template>
  <section>
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-12 text-center mb-5">
          <h1>Bezplatná cenová nabídka na solární{{ '\xa0' }}elektrárnu</h1>
        </div>
        <div class="col-8">
          <div class="card">
            <div class="card-body">
              <div class="pb-4 text-center">
                <h1>Kontaktní fomulář</h1>
                <i>Zpracujeme Vám profesionální nabídku na fotovoltaickou elektrárnu</i>
              </div>
              <VueFormGenerator :schema="getSchema(this)"
                                :model="model"
                                :options="formOptions"
              >
              </VueFormGenerator>
              <hr>
              <h6>
                <div class="float-left">
                  Telefon: <a href="tel:778 500 045">+420 <strong>778 500 045</strong></a>
                </div>
                <div class="float-right">
                  Email: <a href="mailto:info@solarnireseni.cz">info@solarnireseni.cz </a>
                </div>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import VueFormGenerator from "vue-form-generator";
import {api} from "../config";
import axios from "axios";

Vue.use(VueFormGenerator, {
  validators: {
    gdprValidator: (value) => {
      if (value !== true) {
        return ["Potvrďte souhlas se zpracováním osobních údajů"];
      } else {
        return [];
      }
    },
  }
});
export default {
  name: 'Demand',
  components: {},
  data: () => ({
    model: {
      name: '',
      phone: '',
      email: '',
      locality: '',
      note: '',
    },
    formOptions: {
      validateAfterLoad: false,
      validateAfterChanged: false,
      validateAsync: false
    }
  }),
  methods: {
    getSchema: (that) => {
      console.log()
      return {
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "Vaše jméno:",
            model: "name",
            min: 3,
            max: 100,
            required: true,
            validator: 'string',
          },
          {
            type: "input",
            inputType: "email",
            label: "E-mailová adresa:",
            model: "email",
            required: true,
            validator: 'email',
          },
          {
            type: "input",
            inputType: "text",
            label: "Telefonní číslo:",
            model: "phone",
            min: 9,
            max: 16,
            required: true,
            validator: 'string',
          },
          {
            type: "input",
            inputType: "text",
            label: "PSČ nebo adresa odběrného místa",
            model: "locality",
            min: 2,
            max: 100,
            required: false,
            validator: 'string',
          },
          {
            type: "textArea",
            inputType: "text",
            label: "Poznámka",
            model: "note",
            max: 1000,
            required: false,
            rows: 4
          },
          {
            type: "checkbox",
            label: "Souhlasím se zpracováním os. údajů",
            model: "gdpr",
            required: true,
            default: false,
            validator: 'gdprValidator'
          },
          {
            type: "submit",
            buttonText: 'Odeslat ke zpracování',
            onSubmit: () => {
              that.onFormSubmit();
            },
            validateBeforeSubmit: true,
          },
        ]
      }
    },
    onFormSubmit: function () {
      let apiUrl = '';
      let token = '';
      if (process.env.NODE_ENV === 'development') {
        apiUrl = api.urlDev;
        token = 'test'
      } else {
        apiUrl = api.urlProduction;
        token = api.token;
      }

      let gaId = this.$cookies.get("_ga");
      if (gaId.length) {
        gaId = gaId.replace(/^GA\d+\.\d+\./, '');
      }
      let fbclid = this.$cookies.get("fbclid");

      let postData = {
        "secret": token,
        "data": {
          "name": this.model.name,
          "phone": this.model.phone,
          "email": this.model.email,
          "ga_id": gaId,
          "fbclid": fbclid,
          "note": this.model.note
        }
      };
      let headers = {
        "Content-Type": "application/json",
        'X-Requested-With': 'XMLHttpRequest'
      };
      let self = this;
      axios.post(apiUrl, postData, {headers})
          .then(function (response) {
            if (response.status === 200) {
              let responseData = response.data;
              if (responseData.status === 'ok') {
                self.$gtm.trackEvent({
                  event: "form_sent",
                  result: "ok"
                });
                self.$router.push({name: 'Finish'});
              } else {
                self.$gtm.trackEvent({
                  event: "form_sent",
                  result: "api-err-problem"
                });
                //console.log(response);
                self.$router.push({name: 'Finish'});
              }
            } else {
              self.$gtm.trackEvent({
                event: "form_sent",
                result: "api-err-bad_response"
              });
              alert('Oops, nastala chyba. Prosím kontaktujde správce systému na admin@mamzajem.cz. Děkujeme.')
            }
          }).catch(function (error) {
        self.$gtm.trackEvent({
          event: "form_sent",
          result: "api-err-try_catch"
        });
        console.log(error);
        alert('Oops, nastala chyba. Prosím kontaktujde správce systému na admin@mamzajem.cz. Děkujeme.')
      });
    }
  }
}
</script>
<style lang="scss">
.form-group.error .errors {
  font-size: 1rem !important;
}

.form-group.error .errors span {
  padding-left: 20px !important;
  background-size: 20px !important;
}

.form-group.field-checkbox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  flex-wrap: wrap;
  label {
    padding-left: 10px;
  }
  .field-wrap{
    align-self: center;
  }
  input{
    transform: scale(1.6);
  }
}

.vue-form-generator .field-submit input {
  box-shadow: inset 0px 1px 0px 0px #fce2c1 !important;
  background: linear-gradient(to bottom, #ff660cff 20%, #ff8903 80%) !important;
  background-color: #ff660cff !important;
  border-radius: 6px;
  border: 1px solid #eeb44f !important;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 1.2rem !important;
  padding: 6px 24px !important;
  text-decoration: none;
  text-shadow: 0px 1px 5px #000000 !important;

  &:hover {
    background: #d95304 !important;
  }
}
</style>
