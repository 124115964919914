<template>
    <section>
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="py-5 text-center">
                                <h1>Ochrana osobních údajů</h1>
                            </div>
<p>Úkolem těchto zásad ochrany osobních údajů je informovat vás o tom, jak s vašimi údaji nakládáme.
    V souvislosti se svými údaji máte určitá práva, včetně práva odvolat svůj souhlas a práva podávat námitky proti zpracování na základě oprávněných zájmů, profilování (včetně používání uživatelského a obdobného/podobého publika) a přímého marketingu.</p>
                            <h2><strong>OBSAH</strong></h2>
                            <ul>

                                <li>Jak na&scaron;e str&aacute;nky funguj&iacute;</li>
                                <li>Jak&eacute; &uacute;daje shromažďujeme a jak je shromažďujeme</li>
                                <li>Základní ustanovení</li>
                                <li>Zákonný důvod a účel zpracování osobních údajů</li>
                                <li>Doba uchovávání údajů</li>
                                <li>Příjemci osobních údajů</li>
                                <li>Vaše práva</li>
                                <li>Zásady používání souborů cookie</li>
                                <li>Jaké soubory cookie používáme:</li>

                                <li>Jak n&aacute;s kontaktovat</li>
                                <li>Soubory cookie, kter&eacute; použ&iacute;v&aacute;me, a &uacute;čely, za jak&yacute;mi je použ&iacute;v&aacute;me</li>
                                <li>Změny v těchto z&aacute;sad&aacute;ch ochrany osobn&iacute;ch &uacute;dajů</li>
                            </ul>
                            <hr>
                           <h2> JAK NAŠE STRÁNKY FUNGUJÍ</h2>
                           <h3> Vyžádání si nabídek nebo informací</h3>

                          <p>  Naše stránky umožňují lidem vyžádat si cenové nabídky nebo informace o produktu nebo službách zobrazených nebo vybraných na stránkách („produkt“).</p>

                            <p>    Službami, které jsou na našich stránkách poskytovány, je propojení lidí, jež si chtějí zakoupit produkt, s příslušnými poskytovateli produktu („poskytovatelé služeb“).</p>

                            <p>    Pokud si chcete vyžádat cenové nabídky nebo informace o produktu („cenové nabídce“), vyplňte náš webový formulář a zašlete nám žádost.</p>

                            <p>    Jakmile obdržíme váš požadavek, spojíme vás s jedním nebo více příslušnými poskytovateli služeb, kteří vás budou v reakci na tento požadavek kontaktovat. Abychom vás mohli spojit s poskytovatelem (poskytovateli) služeb, sdílíme s těmito poskytovateli služeb informace, které nám poskytnete prostřednictvím našich webových formulářů. Poskytovatelům služeb sdělíme, kdo jste a jaké máte požadavky na produkt, a oni vás poté budou kontaktovat přímo s cenovými nabídkami. V našem webovém formuláři uvidíte, že máme opravdu jasné prohlášení o souhlasu. Toto prohlášení zahrnuje poskytovatele služeb, kteří vás budou kontaktovat.</p>

                            <p>    U některých produktů vás můžeme před spojením s příslušnými poskytovateli služeb požádat o potvrzení vašich údajů nebo požadavků.</p>

                            <p>    Můžete si vybrat, od kterého poskytovatele služeb chcete nakupovat, ale nejste povinni si od některého z nich cokoli koupit.</p>

                            <p>    S výjimkou případů, které v těchto ásadách ochrany osobních údajů uvádíme, nesdílíme vaše údaje s nikým jiným ani je nepoužíváme k jinému účelu, s výjimkou souhrnného a anonymního použití. Znamená to, že je nepřevádíme do žádné reklamní sítě ani je nepoužíváme k vytváření uživatelských profilů.</p>
                            <hr>
                            <H2>JAKÉ ÚDAJE SHROMAŽĎUJEME A JAK JE SHROMAŽĎUJEME</H2>
                            Kontaktní údaje a požadavky na produkt

                            <p>  Pokud vyplníte náš webový formulář za účelem vyžádání si cenové nabídky nebo sjednání si schůzky, dostanete i žádost o vyplnění kontaktních údajů jako je jméno, e-mailová adresa, telefonní číslo, poštovní adresa a/nebo poštovní směrovací číslo.</p>

                            <p>  V našem webovém formuláři také budete požádání o uvedení informací týkajících se svých požadavků na produkt.</p>

                            <p>  Čas od času své webové formuláře měníme, takže se může stát, že budete požádáni o další informace. Z webového formuláře bude vždy zřejmé, jaké informace požadujeme. Navíc požadujeme pouze informace, které jsou relevantní pro váš požadavek a služby, jež poskytujeme.</p>

                            <p>  Pokud vám zavoláme v reakci na vyplnění našeho webového formuláře, můžeme vás požádat o další informace týkající se vaší žádosti.</p>

                            <p>  Vynasnažíme se vám také poskytnout požadované informace, pokud navštívíte naše stránky. To znamená, že vás někdy můžeme požádat o vaše jméno a e-mailovou adresu (prostřednictvím vyskakovacího okna na našich stránkách), abychom vám mohli poslat více informací o produktech nebo službách, o které jste projevili zájem. Nejste povinni nám tyto informace odeslat. Pokud si to navíc později rozmyslíte, můžete se kdykoli odhlásit kliknutím na odkaz pro odhlášení z odběru v e-mailu.</p>
                            <li>Základní ustanovení</li>
                            <ul>
                                <li>
                                    Správcem a zpracovatelem osobních údajů podle čl. 4 bod 7 nařízení Evropského
                                    parlamentu
                                    a Rady
                                    (EU)
                                    2016/679 o
                                    ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu
                                    těchto
                                    údajů (dále
                                    jen: „GDPR”)
                                    je Kaulike s.r.o. IČ 09546618 se sídlem Příkop 843/4
                                  Brno 602 00 vedená u Krajského soudu v Brně C 119526 (dále jen:
                                    „správce“).
                                </li>
                                <li>
                                    Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné
                                    fyzické
                                    osobě;
                                    identifikovatelnou
                                    fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména
                                    odkazem na
                                    určitý
                                    identifikátor, například jméno, identifikační číslo, lokační údaje, síťový
                                    identifikátor
                                    nebo na
                                    jeden
                                    či více
                                    zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní
                                    nebo
                                    společenské
                                    identity této
                                    fyzické osoby.
                                </li>
                                <li>
                                    Správce nejmenoval pověřence pro ochranu osobních údajů.
                                </li>
                            </ul>

                            <li>Zdroje a kategorie zpracovávaných osobních údajů</li>
                            <ul>
                                <li>
                                    Správce zpracovává osobní údaje, které jste mu poskytl/a přímo nebo osobní údaje,
                                    které
                                    správce
                                    získal
                                    na základě vyplněných kontaktních formulářů nebo základě plnění
                                    Vaší objednávky, který správce dále předává dané společnosti, pro kterou byla
                                    objednávka
                                    vytvořena.
                                </li>
                                <li>
                                    Správce zpracovává Vaše identifikační a kontaktní údaje pro marketingové účely,
                                    zejména
                                    zasílání obchodních sdělení, newsletterů a telemarketingu.
                                </li>
                            </ul>
                            <li>Zákonný důvod a účel zpracování osobních údajů</li>
                            <ul>
                                <li>Zákonným důvodem zpracování osobních údajů je</li>
                                <ul>

                                    <li>
                                        oprávněný zájem správce na poskytování přímého marketingu (zejména pro zasílání
                                        obchodních
                                        sdělení, newsletterů a telemarketingu)
                                        podle čl. 6 odst. 1 písm. f) GDPR,
                                    </li>
                                    <li>
                                        Váš souhlas se zpracováním pro účely poskytování přímého marketingu (zejména pro
                                        zasílání
                                        obchodních,
                                        newsletterů a telemarketingu) podle čl. 6 odst. 1 písm. a) GDPR ve spojení s § 7
                                        odst. 2 zákona č. 480/2004
                                        Sb., o
                                        některých službách
                                        informační společnosti v případě, že nedošlo k objednávce zboží nebo služby.
                                    </li>
                                </ul>
                                <li>
                                    Účelem zpracování osobních údajů je
                                </li>
                                <ul>
                                    <li>
                                        vyřízení Vaší objednávky. Při objednávce jsou vyžadovány osobní údaje, které
                                        jsou
                                        nutné pro
                                        úspěšné
                                       nastavení cenové nabídky

                                        bez poskytnutí osobních údajů není možné nabídku specifikovat.
                                        plnit,
                                    </li>
                                    <li>
                                        telefonické kontakování s marketingovými nabídkami.
                                    </li>
                                    <li>
                                        zasílání obchodních sdělení, newsletterů a činění dalších marketingových
                                        aktivit.
                                    </li>
                                    <li>
                                        kontaktování pro účely průzkumu zjištění spokojenosti s kvalitou poskytování
                                        služeb
                                    </li>
                                    <li>
                                        Ze strany správce nedochází k automatickému individuálnímu rozhodování ve smyslu
                                        čl.
                                        22
                                        GDPR. S
                                        takovým
                                        zpracováním
                                        jste poskytl/a svůj výslovný souhlas.
                                    </li>
                                </ul>
                            </ul>
                            <li>Doba uchovávání údajů</li>
                            <ul>
                                <li>Správce uchovává osobní údaje</li>
                                <ul>
                                    <li>
                                        po dobu nezbytnou k výkonu práv a povinností vyplývajících ze smluvního vztahu
                                        mezi Vámi a vybranou společností,
                                        správcem, zpracovatelem a uplatňování nároků z těchto smluvních vztahů (po dobu
                                        10 let od ukončení smluvního vztahu).
                                    </li>
                                    <li>
                                        po dobu, než je odvolán souhlas se zpracováním osobních údajů pro účely
                                        marketingu, nejdéle 10 let, jsou-li osobní
                                        údaje zpracovávány na základě souhlasu.
                                    </li>
                                    <li>po uplynutí doby uchovávání osobních údajů správce osobní údaje vymaže.</li>
                                </ul>
                            </ul>
                            <li>Příjemci osobních údajů (subdodavatelé správce)</li>
                            <ul>
                                <li>
                                    Příjemci osobních údajů jsou osoby
                                </li>
                                <ul>
                                    <li>
                                        podílející se na dodání zboží / služeb / realizaci plateb na základě smlouvy,
                                    </li>
                                    <li>
                                        zajišťující služby provozování webu a další služby v souvislosti s provozováním,
                                    </li>
                                    <li>
                                        zajišťující marketingové služby.
                                    </li>
                                </ul>
                                <li>
                                    Správce nemá v úmyslu předat osobní údaje do třetí země (do země mimo EU) nebo
                                    mezinárodní
                                    organizaci.
                                </li>
                                <li>Provozované služby, zajišťující marketingové a podpůrné služby</li>
                                <ul>
                                    <li>
                                        Google analytics - zaznamenává cookie a použití webu
                                    </li>
                                    <li>
                                        Google Adwords - zaznamenává cookie a použití webu
                                    </li>
                                    <li>
                                        Sklik - zaznamenává cookie, použití webu, konverze nákupu
                                    </li>
                                    <li>
                                        Smartlook - zaznamenává chování a použití webu
                                    </li>
                                </ul>
                            </ul>
                            <li>Vaše práva</li>
                            <ul>
                                <li>Za podmínek stanovených v GDPR máte</li>
                                <ul>

                                    <li>
                                        právo na přístup ke svým osobním údajům dle čl. 15 GDPR,
                                    </li>
                                    <li>
                                        právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení zpracování dle
                                        čl. 18 GDPR.
                                    </li>
                                    <li>
                                        právo na výmaz osobních údajů dle čl. 17 GDPR.
                                    </li>
                                    <li>
                                        právo vznést námitku proti zpracování dle čl. 21 GDPR a
                                    </li>
                                    <li>
                                        právo na přenositelnost údajů dle čl. 20 GDPR.
                                    </li>
                                    <li>
                                        právo odvolat souhlas se zpracováním písemně nebo elektronicky na adresu nebo
                                        email
                                        správce
                                        uvedený
                                        v
                                        čl. III těchto
                                        podmínek. Odvolat souhlas můžete kdykoli ve svém vlastním účtu zákazníka, pokud
                                        stránka
                                        uživatelské účty obsahuje.
                                    </li>
                                </ul>

                                <li>
                                    Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že
                                    se domníváte, že
                                    bylo
                                    porušeno Vaší
                                    právo na ochranu osobních údajů.
                                </li>
                            </ul>
                            <li>Podmínky zabezpečení osobních údajů</li>
                            <ul>
                                <li>
                                    Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k
                                    zabezpečení osobních
                                    údajů.
                                </li>
                                <li>
                                    Správce přijal technická opatření k zabezpečení datových úložišť a úložišť osobních
                                    údajů v listinné
                                    podobě, zejména
                                    zabezpečeného / šifrovaného přístupu na web, šifrování hesel zákazníků v databázi,
                                    pravidelných
                                    aktualizací systému,
                                    pravidelné zálohy systému.
                                </li>
                                <li>
                                    Správce prohlašuje, že k osobním údajům mají přístup pouze jím pověřené osoby.
                                </li>
                            </ul>
                            <li>Závěrečná ustanovení</li>
                            <ul>
                                <li>
                                    Odesláním internetového kontaktního nebo objednávkového formuláře potvrzujete, že
                                    jste seznámen/a s
                                    podmínkami
                                    ochrany osobních údajů a že je v celém rozsahu přijímáte.
                                </li>
                                <li>
                                    S těmito podmínkami souhlasíte zaškrtnutím souhlasu prostřednictvím internetového
                                    formuláře.
                                    Zaškrtnutím
                                    souhlasu
                                    potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém
                                    rozsahu
                                    přijímáte.
                                </li>
                                <li>
                                    Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních
                                    údajů zveřejní na svých internetových stránkách.
                                </li>
                            </ul>

                            <p>
                                Tyto podmínky nabývají účinnosti dnem 25.5.2018.
                                více informací na <a
                                    href="https://www.uoou.cz/">UOOU</a>
                            </p>


                            <hr>


                            <h3>Zásady používání souborů cookie</h3>

                            <p>
                                Co jsou soubory cookies:
                                Za účelem zlepšení Vám poskytovaných služeb využívají naše stránky soubory cookie.
                                Soubory
                                cookie jsou
                                malé
                                soubory, které ukládají informace ve Vašem prohlížeči a běžně slouží k rozlišování
                                jednotlivých
                                uživatelů.
                                Osoba uživatele však není na základě těchto informací identifikovatelná. Soubory cookies
                                tak
                                pomáhají
                                např.:
                            <ul>
                                <li>
                                    ke správné funkčnosti našich stránek, aby bylo možné dokončit proces nákupu s co
                                    nejmenšími
                                    obtížemi;
                                </li>
                                <li>
                                    při zapamatování přihlašovacích údajů našich zákazníků, takže je nemusí pokaždé
                                    zadávat;
                                </li>
                                <li>
                                    při zjišťování, které stránky a funkce používají návštěvníci nejčastěji; na základě
                                    toho
                                    můžeme co
                                    nejlépe přizpůsobit naši nabídku Vašim požadavkům;
                                </li>
                                <li>
                                    pomáhají nám zjistit, které reklamy si návštěvníci nejčastěji prohlíží, aby se jim
                                    při
                                    procházení
                                    stránek nezobrazovala stále stejná reklama, případně aby se jim nezobrazovala
                                    reklama na
                                    zboží, o
                                    které nemají zájem.
                                </li>
                            </ul>
                            Další obecné informace k souborům cookie naleznete např.:<a
                                href="https://cs.wikipedia.org/wiki/HTTP_cookie">zde</a>
                            </p>
                            <h4>Jaké soubory cookie používáme:</h4>

                            <p>
                                Soubory cookie používané na našich stránkách lze z hlediska jejich trvanlivosti rozdělit
                                na
                                dva základní
                                typy. Krátkodobé tzv. „session cookie“ které jsou pouze dočasné a zůstávají uloženy ve
                                Vašem
                                prohlížeči
                                pouze do té doby, než prohlížeč zavřete, a dlouhodobě tzv. „persistent cookie“, které
                                zůstávají uloženy
                                ve
                                Vašem zařízení mnohem déle nebo dokud je ručně neodstraníte (doba ponechání souborů
                                cookie
                                ve vašem
                                zařízení
                                závisí na nastavení samotné cookie a nastavení vašeho prohlížeče).
                                Z hlediska funkce, kterou jednotlivé cookie plní, lze cookie rozdělit na:
                            <ul>
                                <li>
                                    konverzní, které nám umožňují analyzovat výkon různých prodejních kanálů
                                </li>
                                <li>
                                    trackingové (sledovací), které v kombinaci s konverzními pomáhají analyzovat výkon
                                    různých
                                    prodejních kanálů
                                </li>
                                <li>
                                    remarketingové, které používáme pro personalizaci obsahu reklam a jejich správnému
                                    zacílení
                                </li>
                                <li>
                                    analytické, které nám pomáhají zvýšit uživatelské pohodlí našeho webu tím, že
                                    pochopíme,
                                    jak jej
                                    uživatelé
                                    používají
                                </li>
                                <li>
                                    esenciální, které jsou důležité pro základní funkčnost webu
                                </li>
                            </ul>
                            Některé soubory cookie mohou shromažďovat informace, které jsou následně využity třetími
                            stranami a
                            které
                            např. přímo podporují naše reklamní aktivity (tzv. „cookie třetích stran“). Například
                            informace
                            o
                            produktech
                            kupovaných návštěvníky na našich stránkách mohou být zobrazeny reklamní agenturou, abychom
                            mohli
                            lépe
                            uzpůsobit zobrazení internetových reklamních bannerů na Vámi zobrazovaných webových
                            stránkách.
                            Podle
                            těchto
                            údajů Vás však nelze identifikovat.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <section>
             <p><span style="color: #000000;"><html><head><meta charset="utf-8"><title>MVF Global Ltd</title><base target="_blank"></head> <style type='text/css'>body {font-family: arial, helvetica, sans-serif;}</style></span></p>
             <h2 style="text-align: center;"><span style="color: #000000;"><strong>Z&Aacute;SADY OCHRANY OSOBN&Iacute;CH &Uacute;DAJŮ</strong></span></h2>
             <p>&nbsp;</p>
             <p><span style="color: #000000;"><strong>C&iacute;lem těchto z&aacute;sad ochrany osobn&iacute;ch &uacute;dajů je informovat v&aacute;s o tom, jak s va&scaron;imi &uacute;daji nakl&aacute;d&aacute;me. </strong>V souvislosti se sv&yacute;mi &uacute;daji m&aacute;te určit&aacute; pr&aacute;va, včetně pr&aacute;va odvolat svůj souhlas a pr&aacute;va pod&aacute;vat n&aacute;mitky proti zpracov&aacute;n&iacute; na z&aacute;kladě opr&aacute;vněn&yacute;ch z&aacute;jmů, profilov&aacute;n&iacute; (včetně použ&iacute;v&aacute;n&iacute; uživatelsk&eacute;ho a obdobn&eacute;ho/podob&eacute;ho publika) a př&iacute;m&eacute;ho marketingu. Sv&aacute; pr&aacute;va můžete kdykoli uplatnit zasl&aacute;n&iacute;m e-mailu na adresu dataprotection@mvfglobal.com. Dal&scaron;&iacute; podrobnosti o tom, jak data zpracov&aacute;v&aacute;me, a o sv&yacute;ch pr&aacute;vech najdete n&iacute;že.</span></p>
             <h2><span style="color: #000000;"><strong>OBSAH</strong></span></h2>
             <ul>
                 <li><span style="color: #000000;">O n&aacute;s</span></li>
                 <li><span style="color: #000000;">Jak na&scaron;e str&aacute;nky funguj&iacute;</span></li>
                 <li><span style="color: #000000;">Jak&eacute; &uacute;daje shromažďujeme a jak je shromažďujeme</span></li>
                 <li><span style="color: #000000;">Soubory cookie</span></li>
                 <li><span style="color: #000000;">IP adresy</span></li>
                 <li><span style="color: #000000;">Jak va&scaron;e &uacute;daje použ&iacute;v&aacute;me</span></li>
                 <li><span style="color: #000000;">Před&aacute;v&aacute;n&iacute; va&scaron;ich &uacute;dajů</span></li>
                 <li><span style="color: #000000;">Ukl&aacute;d&aacute;n&iacute; &uacute;dajů</span></li>
                 <li><span style="color: #000000;">Pr&aacute;vn&iacute; odůvodněn&iacute;</span></li>
                 <li><span style="color: #000000;">Va&scaron;e pr&aacute;va</span></li>
                 <li><span style="color: #000000;">Pr&aacute;va na soukrom&iacute; platn&aacute; v Kalifornii</span></li>
                 <li><span style="color: #000000;">Jak n&aacute;s kontaktovat</span></li>
                 <li><span style="color: #000000;">Soubory cookie, kter&eacute; použ&iacute;v&aacute;me, a &uacute;čely, za jak&yacute;mi je použ&iacute;v&aacute;me</span></li>
                 <li><span style="color: #000000;">Změny v těchto z&aacute;sad&aacute;ch ochrany osobn&iacute;ch &uacute;dajů</span></li>
             </ul>
             <h3><span style="color: #000000;"><strong>O N&Aacute;S</strong></span></h3>
             <p><span style="color: #000000;"><strong>Expert Market CZ </strong>je značka společnosti Marketing VF Limited (<strong>d&aacute;le</strong><strong>jen &bdquo;MVF&ldquo;, &bdquo;my&ldquo;, &bdquo;n&aacute;s&ldquo;, &bdquo;na&scaron;e&ldquo;)</strong>. MVF je společnost registrovan&aacute; v Anglii a Walesu (č&iacute;slo společnosti: 06951544), se s&iacute;dlem na adrese Imperial Works, Perren Street, Lond&yacute;n NW5 3ED, Velk&aacute; Brit&aacute;nie.</span></p>
             <p><span style="color: #000000;">Pro &uacute;čely Obecn&eacute;ho nař&iacute;zen&iacute; o ochraně osobn&iacute;ch &uacute;dajů (<strong>&bdquo;GDPR&ldquo;</strong>) je spr&aacute;vcem ve vztahu k va&scaron;im &uacute;dajům společnost MVF. Jsme registrov&aacute;ni jako spr&aacute;vce u britsk&eacute;ho dozorč&iacute;ho org&aacute;nu: &Uacute;řad komisaře pro informace (ICO) pod registračn&iacute;m č&iacute;slem Z1995728. Registraci můžete potvrdit zde: <a style="color: #000000;" href="https://ico.org.uk/">www.ico.org.uk</a></span></p>
             <h3><span style="color: #000000;"><strong>JAK NA&Scaron;E STR&Aacute;NKY FUNGUJ&Iacute;</strong></span></h3>
             <p><span style="color: #000000;"><strong>Vyž&aacute;d&aacute;n&iacute; si nab&iacute;dek nebo informac&iacute;</strong></span></p>
             <p><span style="color: #000000;">Na&scaron;e str&aacute;nky umožňuj&iacute; lidem vyž&aacute;dat si cenov&eacute; nab&iacute;dky nebo informace o produktu nebo služb&aacute;ch zobrazen&yacute;ch nebo vybran&yacute;ch na str&aacute;nk&aacute;ch (<strong>&bdquo;produkt&ldquo;)</strong>.</span></p>
             <p><span style="color: #000000;">Službami, kter&eacute; jsou na na&scaron;ich str&aacute;nk&aacute;ch poskytov&aacute;ny, je propojen&iacute; lid&iacute;, jež si chtěj&iacute; zakoupit produkt, s př&iacute;slu&scaron;n&yacute;mi poskytovateli produktu (<strong>&bdquo;poskytovatel&eacute; služeb&ldquo;)</strong>.</span></p>
             <p><span style="color: #000000;">Pokud si chcete vyž&aacute;dat cenov&eacute; nab&iacute;dky nebo informace o produktu (<strong>&bdquo;cenov&eacute; nab&iacute;dce&ldquo;</strong>), vyplňte n&aacute;&scaron; webov&yacute; formul&aacute;ř a za&scaron;lete n&aacute;m ž&aacute;dost.</span></p>
             <p><span style="color: #000000;">Jakmile obdrž&iacute;me v&aacute;&scaron; požadavek, spoj&iacute;me v&aacute;s s jedn&iacute;m nebo v&iacute;ce př&iacute;slu&scaron;n&yacute;mi poskytovateli služeb, kteř&iacute; v&aacute;s budou v reakci na tento požadavek kontaktovat. Abychom v&aacute;s mohli spojit s poskytovatelem (poskytovateli) služeb, sd&iacute;l&iacute;me s těmito poskytovateli služeb informace, kter&eacute; n&aacute;m poskytnete prostřednictv&iacute;m na&scaron;ich webov&yacute;ch formul&aacute;řů. Poskytovatelům služeb sděl&iacute;me, kdo jste a jak&eacute; m&aacute;te požadavky na produkt, a oni v&aacute;s pot&eacute; budou kontaktovat př&iacute;mo s cenov&yacute;mi nab&iacute;dkami. V na&scaron;em webov&eacute;m formul&aacute;ři uvid&iacute;te, že m&aacute;me opravdu jasn&eacute; prohl&aacute;&scaron;en&iacute; o souhlasu. Toto prohl&aacute;&scaron;en&iacute; zahrnuje poskytovatele služeb, kteř&iacute; v&aacute;s budou kontaktovat.</span></p>
             <p><span style="color: #000000;">U někter&yacute;ch produktů v&aacute;s můžeme před spojen&iacute;m s př&iacute;slu&scaron;n&yacute;mi poskytovateli služeb pož&aacute;dat o potvrzen&iacute; va&scaron;ich &uacute;dajů nebo požadavků.</span></p>
             <p><span style="color: #000000;">Můžete si vybrat, od kter&eacute;ho poskytovatele služeb chcete nakupovat, ale nejste povinni si od někter&eacute;ho z nich cokoli koupit.</span></p>
             <p><span style="color: #000000;">S v&yacute;jimkou př&iacute;padů, kter&eacute; v těchto &aacute;sad&aacute;ch ochrany osobn&iacute;ch &uacute;dajů uv&aacute;d&iacute;me, nesd&iacute;l&iacute;me va&scaron;e &uacute;daje s nik&yacute;m jin&yacute;m ani je nepouž&iacute;v&aacute;me k jin&eacute;mu &uacute;čelu, s v&yacute;jimkou souhrnn&eacute;ho a anonymn&iacute;ho použit&iacute;. Znamen&aacute; to, že je nepřev&aacute;d&iacute;me do ž&aacute;dn&eacute; reklamn&iacute; s&iacute;tě ani je nepouž&iacute;v&aacute;me k vytv&aacute;řen&iacute; uživatelsk&yacute;ch profilů.</span></p>
             <p><span style="color: #000000;"><strong>Ž&aacute;dost o domluven&iacute; schůzky</strong></span></p>
             <p><span style="color: #000000;">U určit&yacute;ch zdravotnick&yacute;ch produktů (např. naslouchadla) eventu&aacute;lně na&scaron;e str&aacute;nky umožn&iacute; lidem naj&iacute;t a kontaktovat př&iacute;slu&scaron;n&eacute; zdravotnick&eacute; odborn&iacute;ky (tak&eacute; &bdquo;<strong>poskytovatel&eacute; služeb</strong>&ldquo;) a/nebo si sjednat s takov&yacute;mi poskytovateli služeb schůzku.</span></p>
             <p><span style="color: #000000;">Pokud chcete kontaktovat poskytovatele služeb a/nebo si s n&iacute;m sjednat schůzku, vyplňte a ode&scaron;lete n&aacute;&scaron; webov&yacute; formul&aacute;ř.</span></p>
             <p><span style="color: #000000;">Jakmile obdrž&iacute;me v&aacute;&scaron; dotaz, provedeme někter&yacute; z těchto kroků:&nbsp;</span></p>
             <ul>
                 <li><span style="color: #000000;">Spoj&iacute;me v&aacute;s př&iacute;mo s jedn&iacute;m nebo v&iacute;ce př&iacute;slu&scaron;n&yacute;mi poskytovateli služeb. Poskytovatelům služeb sděl&iacute;me, kdo jste, a poskytneme jim informace, kter&eacute; jste uvedli ve webov&eacute;m formul&aacute;ři (jak je uvedeno n&iacute;že). Poskytovatel se s v&aacute;mi spoj&iacute; a domluv&iacute; si schůzku. V na&scaron;em webov&eacute;m formul&aacute;ři uvid&iacute;te, že m&aacute;me opravdu jasn&eacute; prohl&aacute;&scaron;en&iacute; o souhlasu. Toto prohl&aacute;&scaron;en&iacute; zahrnuje poskytovatele služeb, kteř&iacute; v&aacute;s budou kontaktovat.</span></li>
                 <li><span style="color: #000000;">Př&iacute;padně v&aacute;s oslov&iacute;me, a to buď pod na&scaron;&iacute;m obchodn&iacute;m jm&eacute;nem, nebo jm&eacute;nem poskytovatele služeb, abychom si s v&aacute;mi schůzku smluvili.</span></li>
             </ul>
             <p><span style="color: #000000;">U ž&aacute;dn&eacute;ho z poskytovatelů služeb, se kter&yacute;mi v&aacute;s spoj&iacute;me, si nejste povini sjednat schůzku či si u něj něco zakoupit.&nbsp;</span></p>
             <h3><span style="color: #000000;"><strong>JAK&Eacute; &Uacute;DAJE SHROMAŽĎUJEME A JAK JE SHROMAŽĎUJEME</strong></span></h3>
             <p><span style="color: #000000;"><strong>Kontaktn&iacute; &uacute;daje a požadavky na produkt</strong></span></p>
             <p><span style="color: #000000;">Pokud vypln&iacute;te n&aacute;&scaron; webov&yacute; formul&aacute;ř za &uacute;čelem vyž&aacute;d&aacute;n&iacute; si cenov&eacute; nab&iacute;dky nebo sjedn&aacute;n&iacute; si schůzky, dostanete i ž&aacute;dost o vyplněn&iacute; kontaktn&iacute;ch &uacute;dajů jako je jm&eacute;no, e-mailov&aacute; adresa, telefonn&iacute; č&iacute;slo, po&scaron;tovn&iacute; adresa a/nebo po&scaron;tovn&iacute; směrovac&iacute; č&iacute;slo.</span></p>
             <p><span style="color: #000000;">V na&scaron;em webov&eacute;m formul&aacute;ři tak&eacute; budete pož&aacute;d&aacute;n&iacute; o uveden&iacute; informac&iacute; t&yacute;kaj&iacute;c&iacute;ch se sv&yacute;ch požadavků na produkt.&nbsp;</span></p>
             <p><span style="color: #000000;">Čas od času sv&eacute; webov&eacute; formul&aacute;ře měn&iacute;me, takže se může st&aacute;t, že budete pož&aacute;d&aacute;ni o dal&scaron;&iacute; informace. Z webov&eacute;ho formul&aacute;ře bude vždy zřejm&eacute;, jak&eacute; informace požadujeme. Nav&iacute;c požadujeme pouze informace, kter&eacute; jsou relevantn&iacute; pro v&aacute;&scaron; požadavek a služby, jež poskytujeme.</span></p>
             <p><span style="color: #000000;">Pokud v&aacute;m zavol&aacute;me v reakci na vyplněn&iacute; na&scaron;eho webov&eacute;ho formul&aacute;ře, můžeme v&aacute;s pož&aacute;dat o dal&scaron;&iacute; informace t&yacute;kaj&iacute;c&iacute; se va&scaron;&iacute; ž&aacute;dosti.</span></p>
             <p><span style="color: #000000;">Vynasnaž&iacute;me se v&aacute;m tak&eacute; poskytnout požadovan&eacute; informace, pokud nav&scaron;t&iacute;v&iacute;te na&scaron;e str&aacute;nky. To znamen&aacute;, že v&aacute;s někdy můžeme pož&aacute;dat o va&scaron;e jm&eacute;no a e-mailovou adresu (prostřednictv&iacute;m vyskakovac&iacute;ho okna na na&scaron;ich str&aacute;nk&aacute;ch), abychom v&aacute;m mohli poslat v&iacute;ce informac&iacute; o produktech nebo služb&aacute;ch, o kter&eacute; jste projevili z&aacute;jem. Nejste povinni n&aacute;m tyto informace odeslat. Pokud si to nav&iacute;c později rozmysl&iacute;te, můžete se kdykoli odhl&aacute;sit kliknut&iacute;m na odkaz pro odhl&aacute;&scaron;en&iacute; z odběru v e-mailu.</span></p>
             <p><span style="color: #000000;"><strong>Informace o va&scaron;em zdrav&iacute;</strong></span></p>
             <p><span style="color: #000000;">Pokud jste vyplnili webov&yacute; formul&aacute;ř t&yacute;kaj&iacute;c&iacute; se zdravotn&iacute;ho produktu, můžeme v&aacute;s ve webov&eacute;m formul&aacute;ři pož&aacute;dat o někter&eacute; citliv&eacute; osobn&iacute; &uacute;daje (např. informace o va&scaron;em zdrav&iacute;).&nbsp;</span></p>
             <p><span style="color: #000000;">Pokud v&aacute;m v reakci na tuto ž&aacute;dost zavol&aacute;me, můžeme v&aacute;s tak&eacute; pož&aacute;dat o informace o va&scaron;em zdrav&iacute;.&nbsp;</span></p>
             <p><span style="color: #000000;">O informace o va&scaron;em zdravotn&iacute;m stavu v&aacute;s pož&aacute;d&aacute;me pouze v př&iacute;padech, kdy je to relevantn&iacute; v souvislosti s va&scaron;&iacute;m požadavkem. Tento typ informac&iacute; budeme z&iacute;sk&aacute;vat pouze s va&scaron;&iacute;m souhlasem.</span></p>
             <p><span style="color: #000000;"><strong>IP adresy</strong></span></p>
             <p><span style="color: #000000;">Ukl&aacute;d&aacute;me a sd&iacute;l&iacute;me tak&eacute; adresy internetov&eacute;ho protokolu (IP), jak je vysvětleno n&iacute;že. Každ&yacute; poč&iacute;tač nebo zař&iacute;zen&iacute; m&aacute; IP adresu (jedinečn&eacute; č&iacute;slo), kter&aacute; mu umožňuje komunikovat s ostatn&iacute;mi poč&iacute;tači a zař&iacute;zen&iacute;mi přes internet.</span></p>
             <p><span style="color: #000000;"><strong>Zaznamen&aacute;v&aacute;n&iacute; hovorů</strong></span></p>
             <p><span style="color: #000000;">Digit&aacute;lně monitorujeme a/nebo zaznamen&aacute;v&aacute;me hovory mezi v&aacute;mi a n&aacute;mi pro &uacute;čely kontroly kvality a &scaron;kolen&iacute; zaměstnanců. Př&iacute;ležitostně můžeme tak&eacute; potřebovat poskytovatelům služeb poskytnout omezen&yacute; př&iacute;stup k takov&yacute;m nahr&aacute;vk&aacute;m, abychom mohli vyře&scaron;it spory. Uvědomte si pros&iacute;m, že citliv&eacute; osobn&iacute; &uacute;daje mohou b&yacute;t zaznamen&aacute;v&aacute;ny, pokud n&aacute;m dobrovolně sděl&iacute;te zdravotn&iacute; informace telefonicky.</span></p>
             <p><span style="color: #000000;"><strong>Dal&scaron;&iacute; informace</strong></span></p>
             <p><span style="color: #000000;">Pokud se rozhodnete st&aacute;hnout a použ&iacute;vat na&scaron;i aplikaci, aby se v&aacute;m s n&iacute; l&eacute;pe pracovalo, může doj&iacute;t ke shrom&aacute;žděn&iacute; dal&scaron;&iacute;ch informac&iacute;. Prov&aacute;d&iacute;me to pomoc&iacute; n&aacute;stroje pro sledov&aacute;n&iacute;, kter&yacute; zjist&iacute; v&aacute;&scaron; online identifik&aacute;tor, geolokaci, specifikaci zař&iacute;zen&iacute;, IČ reklamy Google a dal&scaron;&iacute; &uacute;daje o chov&aacute;n&iacute; souvisej&iacute;c&iacute; s použ&iacute;v&aacute;n&iacute;m na&scaron;&iacute; aplikace.</span></p>
             <p><span style="color: #000000;">Kromě toho o v&aacute;s můžeme shromažďovat &uacute;daje, pokud:</span></p>
             <ul>
                 <li><span style="color: #000000;">N&aacute;s sami kontaktujete nebo v&aacute;s my kontaktujeme e-mailem, telefonicky nebo po&scaron;tou.</span></li>
                 <li><span style="color: #000000;">Zveřejn&iacute;te na na&scaron;ich str&aacute;nk&aacute;ch koment&aacute;ře, materi&aacute;ly nebo jin&yacute; obsah.</span></li>
             </ul>
             <h3><span style="color: #000000;"><strong>SOUBORY COOKIE</strong></span></h3>
             <p><span style="color: #000000;">Soubory cookie jsou mal&eacute; textov&eacute; soubory. Do poč&iacute;tače v&aacute;m je um&iacute;sťuj&iacute; webov&eacute; str&aacute;nky, kter&eacute; nav&scaron;t&iacute;v&iacute;te. Často se použ&iacute;vaj&iacute; k tomu, aby webov&eacute; str&aacute;nky fungovaly nebo aby fungovaly efektivněji a tak&eacute; aby poskytovaly určit&eacute; informace vlastn&iacute;kům str&aacute;nek. V doln&iacute; č&aacute;sti těchto z&aacute;sad je tabulka, v n&iacute;ž uv&aacute;d&iacute;me, jak&eacute; soubory cookie použ&iacute;v&aacute;me, a &uacute;čely, pro kter&eacute; je použ&iacute;v&aacute;me.</span></p>
             <p><span style="color: #000000;">Vět&scaron;ina prohl&iacute;žečů umožňuje vět&scaron;inu souborů cookie do jist&eacute; m&iacute;ry spravovat pomoc&iacute; nastaven&iacute; prohl&iacute;žeče. Dal&scaron;&iacute; informace o souborech cookie, včetně toho, jak zobrazit, kter&yacute; soubor cookie byl nastaven, jak je spravovat a jak je smazat, najdete na <a style="color: #000000;" href="http://www.aboutcookies.org/">www.aboutcookies.org</a> nebo <a style="color: #000000;" href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>.</span></p>
             <p><span style="color: #000000;">Pokud budete cht&iacute;t deaktivovat sledov&aacute;n&iacute; prostřednictv&iacute;m služby Google Analytics na v&scaron;ech webech, postupujte podle pokynů uveden&yacute;ch na <a style="color: #000000;" href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaopto</a></span></p>
             <h3><span style="color: #000000;"><strong>IP ADRESY</strong></span></h3>
             <p><span style="color: #000000;">IP adresy ukl&aacute;d&aacute;me za &uacute;čelem zastaven&iacute; než&aacute;douc&iacute;ho provozu (tj. spamu) na na&scaron;ich str&aacute;nk&aacute;ch. Pokud se např&iacute;klad dozv&iacute;me, že spam poch&aacute;z&iacute; z konkr&eacute;tn&iacute; IP adresy (tj. poč&iacute;tače nebo um&iacute;stěn&iacute;), můžeme tuto IP adresu zablokovat.&nbsp;</span></p>
             <p><span style="color: #000000;">Čas od času tak&eacute; sd&iacute;l&iacute;me IP adresy s poskytovateli služeb, abychom jim umožnili si ověřit, zda ž&aacute;dosti o cenov&eacute; nab&iacute;dky poch&aacute;zej&iacute; z určit&eacute; země.</span></p>
             <h3><span style="color: #000000;"><strong>JAK VA&Scaron;E &Uacute;DAJE POUŽ&Iacute;V&Aacute;ME</strong></span></h3>
             <p><span style="color: #000000;">Va&scaron;e &uacute;daje (popsan&eacute; v&yacute;&scaron;e) použ&iacute;v&aacute;me n&aacute;sleduj&iacute;c&iacute;mi způsoby.</span></p>
             <p><span style="color: #000000;"><strong>Spojen&iacute; s př&iacute;slu&scaron;n&yacute;mi poskytovateli služeb</strong></span></p>
             <p><span style="color: #000000;">Jak je vysvětleno v&yacute;&scaron;e, pokud ode&scaron;lete n&aacute;&scaron; webov&yacute; formul&aacute;ř, před&aacute;me v&aacute;&scaron; požadavek jednomu nebo v&iacute;ce př&iacute;slu&scaron;n&yacute;m poskytovatelům služeb (uveden&yacute;m v prohl&aacute;&scaron;en&iacute; o souhlasu). Provedeme to bezpečn&yacute;m způsobem. Např. za použit&iacute; protokolu HTTP Secure (HTTPS), což znamen&aacute;, že data jsou &scaron;ifrov&aacute;na. Poskytovatel&eacute; služeb v&aacute;s pak kontaktuj&iacute; př&iacute;mo s konkr&eacute;tn&iacute;mi cenov&yacute;mi nab&iacute;dkami. Mohou v&aacute;s kontaktovat telefonicky, textovou zpr&aacute;vou / SMS nebo e-mailem.</span></p>
             <p><span style="color: #000000;">Než před&aacute;me v&aacute;&scaron; požadavek jak&eacute;mukoli poskytovateli služeb, může na&scaron;e služba zahrnovat prvek automatizovan&eacute;ho rozhodov&aacute;n&iacute;. Na&scaron;e technologick&aacute; platforma může automaticky posoudit va&scaron;e požadavky na produkty na z&aacute;kladě informac&iacute;, kter&eacute; jste n&aacute;m poskytli, a předat v&aacute;&scaron; požadavek takov&yacute;m poskytovatelům služeb, jež chtěj&iacute; dost&aacute;vat ž&aacute;dosti o cenov&eacute; nab&iacute;dky v době, kdy je v&aacute;&scaron; požadavek pod&aacute;n.</span></p>
             <p><span style="color: #000000;">Někteř&iacute; poskytovatel&eacute; služeb mohou va&scaron;e &uacute;daje před&aacute;vat extern&iacute;m kontroln&iacute;m agentur&aacute;m v r&aacute;mci va&scaron;&iacute; ž&aacute;dosti o konkr&eacute;tn&iacute; finančn&iacute; produkty, přičemž o t&eacute;to skutečnosti budete informov&aacute;ni v prohl&aacute;&scaron;en&iacute; o souhlasu.&nbsp;</span></p>
             <p><span style="color: #000000;"><strong>Kontaktov&aacute;n&iacute; za &uacute;čelem potvrzen&iacute; va&scaron;ich &uacute;dajů nebo požadavků</strong></span></p>
             <p><span style="color: #000000;">U někter&yacute;ch produktů v&aacute;s můžeme před spojen&iacute;m s př&iacute;slu&scaron;n&yacute;mi poskytovateli služeb pož&aacute;dat o potvrzen&iacute; va&scaron;ich &uacute;dajů nebo požadavků.</span></p>
             <p><span style="color: #000000;">Můžeme v&aacute;m tak&eacute; zavolat a zeptat se na va&scaron;e zku&scaron;enosti s použ&iacute;v&aacute;n&iacute;m na&scaron;ich str&aacute;nek a služeb a na jedn&aacute;n&iacute; s poskytovateli služeb, se kter&yacute;mi jsme v&aacute;s propojili.</span></p>
             <p><span style="color: #000000;"><strong>Kontaktov&aacute;n&iacute; za &uacute;čelem domluven&iacute; si schůzky</strong></span></p>
             <p><span style="color: #000000;">Pokud jste pož&aacute;dali o sjedn&aacute;n&iacute; schůzky, můžeme v&aacute;m zavolat, a to buď pod na&scaron;&iacute;m obchodn&iacute;m jm&eacute;nem, nebo jm&eacute;nem poskytovatele služeb, abychom si s v&aacute;mi schůzku smluvili.&nbsp;</span></p>
             <p><span style="color: #000000;">Pokud v&aacute;m domluv&iacute;me schůzku, sděl&iacute;me poskytovateli služeb va&scaron;e &uacute;daje včetně v&scaron;ech informac&iacute;, kter&eacute; během hovoru poskytnete. Tak&eacute; v&aacute;m řekneme, u kter&eacute;ho poskytovatele služeb jsme v&aacute;m schůzku domluvili. Bez va&scaron;eho souhlasu schůzku domlouvat nebudeme.</span></p>
             <p><span style="color: #000000;">Občas se ale může st&aacute;t, že pro v&aacute;s nebudeme schopni vhodnou schůzku naj&iacute;t. V takov&eacute;m př&iacute;padě v&aacute;m d&aacute;me vědět. Můžeme v&scaron;ak spolupracovat s dal&scaron;&iacute;mi poskytovateli služeb, kteř&iacute; v&aacute;m mohou pomoci. V takov&eacute;m př&iacute;padě před&aacute;me v&aacute;&scaron; dotaz těmto poskytovatelům služeb (kteř&iacute; jsou uvedeni v prohl&aacute;&scaron;en&iacute; o souhlasu nebo sděleni během hovoru), kteř&iacute; se s v&aacute;mi spoj&iacute; př&iacute;mo telefonicky, textovou zpr&aacute;vou / SMS nebo e-mailem.</span></p>
             <p><span style="color: #000000;"><strong>Za &uacute;čelem zasl&aacute;n&iacute; e-mailu nebo SMS o podobn&yacute;ch produktech a služb&aacute;ch &ndash; </strong><strong>Ale pouze v př&iacute;padě, že jste si odhl&aacute;sili odběr takov&yacute;ch e-mailů nebo textů</strong></span></p>
             <p><span style="color: #000000;">Pokud použ&iacute;v&aacute;te na&scaron;e služby spoč&iacute;vaj&iacute;c&iacute; ve vyž&aacute;d&aacute;n&iacute; si cenov&eacute; nab&iacute;dky, r&aacute;di bychom v&aacute;m jako st&aacute;vaj&iacute;c&iacute;mu uživateli poslali e-mail nebo textovou zpr&aacute;vu / SMS o podobn&yacute;ch produktech a služb&aacute;ch, u kter&yacute;ch si mysl&iacute;me, že by v&aacute;s mohly zaj&iacute;mat cenov&eacute; nab&iacute;dky nebo informace (<strong>&bdquo;ostatn&iacute; produkty&ldquo;)</strong>. Někdy v&aacute;m v&aacute;&scaron; mobiln&iacute; oper&aacute;tor může &uacute;čtovat za takov&eacute; SMS zpr&aacute;vy poplatky.&nbsp;</span></p>
             <p><span style="color: #000000;">Vždy budete m&iacute;t možnost se z odběru zpr&aacute;v odhl&aacute;sit, než zpr&aacute;vy ode&scaron;leme. Pokud odhl&aacute;&scaron;en&iacute; odběru provedete, nebudeme je odes&iacute;lat.</span></p>
             <p><span style="color: #000000;"><strong>Za &uacute;čelem vytvořen&iacute; uživatelsk&eacute;ho a obdobn&eacute;ho/podobn&eacute;ho publika.</strong></span></p>
             <p><span style="color: #000000;">R&aacute;di spolupracujeme s Facebookem, Googlem a dal&scaron;&iacute;mi platformami (<strong>&bdquo;platformy&ldquo;</strong>), abychom v&aacute;s mohli kontaktovat s nab&iacute;dkou reklam na dal&scaron;&iacute; produkty. R&aacute;di tak&eacute; využ&iacute;v&aacute;me platformy k osloven&iacute; dal&scaron;&iacute;ch lid&iacute;, kteř&iacute; by r&aacute;di využili na&scaron;e služby t&yacute;kaj&iacute;c&iacute; se vyž&aacute;d&aacute;n&iacute; si cenov&eacute; nab&iacute;dky.</span></p>
             <p><span style="color: #000000;">Děl&aacute;me to dvěma způsoby. V obou př&iacute;padech se va&scaron;e &uacute;daje spolu s dal&scaron;&iacute;mi &uacute;daji použ&iacute;vaj&iacute; k vytvořen&iacute; uživatelsk&eacute;ho publika a/nebo obdobn&eacute;ho/podobn&eacute;ho publika. Kdykoli se publikum sd&iacute;l&iacute; s platformou, &uacute;daje jsou nejprve ha&scaron;ov&aacute;ny a pseudonymizov&aacute;ny, což znamen&aacute;, že v&scaron;echna data v r&aacute;mci publika, kter&aacute; by mohla v&eacute;st k identifikaci určit&eacute; osoby, jsou nahrazena uměl&yacute;m identifik&aacute;torem. Proces je tud&iacute;ž bezpečn&yacute;. Tak&eacute; nesd&iacute;l&iacute;me v&iacute;ce dat, než nezbytně potřebujeme pro &uacute;čely vytv&aacute;řen&iacute; publika.</span></p>
             <ul>
                 <li><span style="color: #000000;"><strong>Uživatelsk&eacute; publikum</strong> &ndash; Uživatelsk&eacute; publikum je seznam st&aacute;vaj&iacute;c&iacute;ch uživatelů, kteř&iacute; využ&iacute;vaj&iacute; na&scaron;ich služeb (<strong>&bdquo;st&aacute;vaj&iacute;c&iacute; uživatel&eacute;&ldquo;</strong>). Čas od času tato data sd&iacute;l&iacute;me s Platformami. Jak je vysvětleno v&yacute;&scaron;e, &uacute;daje jsou nejprve ha&scaron;ov&aacute;ny a pseudonymizov&aacute;ny. Platforma využ&iacute;v&aacute; toto publikum k zobrazen&iacute; na&scaron;ich reklam na jin&eacute; produkty na&scaron;&iacute;m st&aacute;vaj&iacute;c&iacute;m uživatelům.</span></li>
                 <li><span style="color: #000000;"><strong>Obdobn&eacute;/podobn&eacute; publikum</strong> &ndash; Obdobn&eacute;/podobn&eacute; publikum je publikum vytvořen&eacute; platformou. Čas od času sd&iacute;l&iacute;me s platformami seznam st&aacute;vaj&iacute;c&iacute;ch uživatelů. Platforma použ&iacute;v&aacute; tento seznam k nalezen&iacute; a vytvořen&iacute; podobn&yacute;ch uživatelů (tj. jin&yacute;ch uživatelů platformy, kteř&iacute; maj&iacute; podobn&eacute; vlastnosti jako st&aacute;vaj&iacute;c&iacute; uživatel&eacute;), jimž zobrazuje na&scaron;e reklamy. Jak bylo vysvětleno v&yacute;&scaron;e, &uacute;daje jsou nejprve ha&scaron;ov&aacute;ny a pseudonymizov&aacute;ny.</span></li>
             </ul>
             <p><span style="color: #000000;">Jak je uvedeno n&iacute;že, proti t&eacute;to aktivitě můžete kdykoli vzn&eacute;st n&aacute;mitky. Stač&iacute; zaslat e-mail na adresu dataprotection@mvfglobal.com.</span></p>
             <p><span style="color: #000000;"><strong>Aby se v&aacute;m l&eacute;pe pracovalo s aplikac&iacute; a pro přiřazov&aacute;n&iacute; marketingov&eacute;ho obsahu&nbsp;</strong></span></p>
             <p><span style="color: #000000;">&Uacute;daje z&iacute;skan&eacute; prostřednictv&iacute;m na&scaron;&iacute; aplikace můžeme použ&iacute;t k lep&scaron;&iacute;mu pochopen&iacute; va&scaron;&iacute; interakce s na&scaron;&iacute;m obsahem a ke sledov&aacute;n&iacute; toho, jak na&scaron;e marketingov&eacute; kampaně odpov&iacute;daj&iacute; va&scaron;im z&aacute;jmům.&nbsp;</span></p>
             <p><span style="color: #000000;"><strong>K ověřen&iacute; toho, zda odesl&aacute;n&iacute;m webov&eacute;ho formul&aacute;ře byla pod&aacute;na skutečn&aacute; ž&aacute;dost, ale pouze v př&iacute;padě, že se va&scaron;e ž&aacute;dost t&yacute;k&aacute; finančn&iacute;ho produktu nebo finančn&iacute;ch služeb</strong></span></p>
             <p><span style="color: #000000;">Spolupracujeme s dodavatelem (třet&iacute; stranou), kontaktn&iacute;m st&aacute;tem, abychom mohli va&scaron;i ž&aacute;dost předat sv&yacute;m poskytovatelům služeb spr&aacute;vně. Kontaktn&iacute; st&aacute;t poskytuje na&scaron;im poskytovatelům služeb ověřovac&iacute; služby. Kontaktn&iacute; st&aacute;t od n&aacute;s obdrž&iacute; anonymizovan&aacute; data t&yacute;kaj&iacute;c&iacute; se va&scaron;eho požadavku. &bdquo;Anonymizovan&aacute;&ldquo; znamen&aacute;, že nikdo nebude schopen určit va&scaron;i skutečnou identitu na z&aacute;kladě &uacute;dajů, kter&eacute; od n&aacute;s obdrž&iacute;. Neposkytujeme př&iacute;stup k ž&aacute;dn&yacute;m kontaktn&iacute;m &uacute;dajům, kter&eacute; by mohly b&yacute;t použity k va&scaron;&iacute; identifikaci jako jednotliv&eacute; osoby. Kontaktn&iacute; st&aacute;t použ&iacute;v&aacute; tyto anonymizovan&eacute; &uacute;daje, aby n&aacute;m stanovil pravděpodobnost, že v&aacute;&scaron; požadavek byl předložen &bdquo;skutečn&yacute;m&ldquo; člověkem, a nikoli robotem nebo automatick&yacute;m gener&aacute;torem požadavků. Prov&aacute;d&iacute; se to tak, že se prověř&iacute; &bdquo;otisk prohl&iacute;žeče&ldquo; spojen&yacute; s va&scaron;&iacute; ž&aacute;dost&iacute;. Ten obsahuje např&iacute;klad podrobnosti o modelu zař&iacute;zen&iacute;, kter&eacute; použ&iacute;v&aacute;te, internetov&eacute;m prohl&iacute;žeči a oblasti, z n&iacute;ž ž&aacute;dost odes&iacute;l&aacute;te.&nbsp;</span></p>
             <p><span style="color: #000000;">Kontaktn&iacute; st&aacute;t m&aacute; s&iacute;dlo ve Velk&eacute; Brit&aacute;nii a neulož&iacute; va&scaron;e anonymizovan&eacute; &uacute;daje mimo Velkou Brit&aacute;nii a Evropskou unii. Pokud nechcete, abychom va&scaron;e anonymizovan&eacute; &uacute;daje předali kontaktn&iacute;mu st&aacute;tu, sdělte n&aacute;m to na e-mailovou adresu dataprotection@mvfglobal.com. Dal&scaron;&iacute; informace o tom, jak kontaktn&iacute; st&aacute;t tyto &uacute;daje použ&iacute;v&aacute;, se dozv&iacute;te zde &ndash; https://www.contactstate.com/legal/prospect-notice.</span></p>
             <p><span style="color: #000000;"><strong>K připomenut&iacute;, abyste dokončili n&aacute;&scaron; webov&yacute; formul&aacute;ř v př&iacute;padě, že do&scaron;lo k jeho č&aacute;stečn&eacute;mu vyplněn&iacute;, prostřednctv&iacute;m emailu</strong></span></p>
             <p><span style="color: #000000;">Pokud začnete vyplňovat jeden z na&scaron;ich webov&yacute;ch formul&aacute;řů, ale nedokonč&iacute;te ho, můžeme v&aacute;m zaslat e-mail s připomenut&iacute;m, abyste tak učinili. V&aacute;mi zadan&eacute; &uacute;daje budeme ukl&aacute;dat pouze v č&aacute;stečně vyplněn&eacute;m webov&eacute;m formul&aacute;ři, a to po dobu dvou hodin od okamžiku, kdy ho opust&iacute;te. Pokud se do dvou hodin k webov&eacute;mu formul&aacute;ři nevr&aacute;t&iacute;te a nevypln&iacute;te ho ani neode&scaron;lete, &uacute;daje, kter&eacute; jste zadali, trvale smažeme s v&yacute;jimkou automatick&eacute; z&aacute;lohy těchto &uacute;dajů, jež bude vytvořena a uložena po dobu 30 dnů ode dne, kdy jste n&aacute;&scaron; formul&aacute;ř č&aacute;stečně vyplnili. Takov&aacute; z&aacute;loha pro n&aacute;s nebude snadno dostupn&aacute; a va&scaron;e &uacute;daje nebudou použity k ž&aacute;dn&eacute;mu jin&eacute;mu &uacute;čelu.</span></p>
             <p><span style="color: #000000;"><strong>Př&iacute;jem &uacute;dajů od poskytovatelů služeb</strong></span></p>
             <p><span style="color: #000000;">Poskytovatel&eacute; služeb n&aacute;m mohou &uacute;daje čas od času vracet. Mohou tak učinit, pokud maj&iacute; dotaz t&yacute;kaj&iacute;c&iacute; se ž&aacute;dosti o cenovou nab&iacute;dku nebo dotaz, kter&yacute; jsme jim poslali, abychom mohli ho vyře&scaron;it. Mohou n&aacute;m tak&eacute; vr&aacute;tit &uacute;daje spolu s ozn&aacute;men&iacute;m o tom, zda produkt byl nebo nebyl zakoupen, abychom mohli zlep&scaron;it na&scaron;i marketingovou strategii. Tyto &uacute;daje můžeme použ&iacute;vat tak&eacute; k vytv&aacute;řen&iacute; uživatelsk&eacute;ho a obdobn&eacute;ho/podobn&eacute;ho publika, jak je vysvětleno v&yacute;&scaron;e.</span></p>
             <p><span style="color: #000000;"><strong>Spolupr&aacute;ce s dodavateli</strong></span></p>
             <p><span style="color: #000000;">Spolupracujeme s řadou dodavatelů, kteř&iacute; zpracov&aacute;vaj&iacute; &uacute;daje na&scaron;&iacute;m jm&eacute;nem. Patř&iacute; mezi ně poskytovatel&eacute; cloudov&eacute;ho &uacute;loži&scaron;tě, poskytovatel&eacute; e-mailov&yacute;ch služeb a poskytovatel&eacute; cloudov&eacute; telefonie. S těmito dodavateli m&aacute;me uzavřeny př&iacute;slu&scaron;n&eacute; smlouvy, kter&eacute; zajist&iacute; ochranu a důvěrnost &uacute;dajů.</span></p>
             <h3><span style="color: #000000;"><strong>PŘED&Aacute;V&Aacute;N&Iacute; VA&Scaron;ICH &Uacute;DAJŮ</strong></span></h3>
             <p><span style="color: #000000;">V z&aacute;vislosti na tom, v kter&yacute;ch zem&iacute;ch hled&aacute;te poskytovatele služeb, můžeme va&scaron;e &uacute;daje před&aacute;vat i mimo Velkou Brit&aacute;nii. Od 1. ledna 2021 již Velk&aacute; Brit&aacute;nie nen&iacute; souč&aacute;st&iacute; Evropsk&eacute;ho hospod&aacute;řsk&eacute;ho prostoru (d&aacute;le jen &bdquo;<strong>EHP&ldquo;</strong>). To znamen&aacute;, že va&scaron;e &uacute;daje mohou b&yacute;t před&aacute;v&aacute;ny mimo prostor Velk&eacute; Brit&aacute;nie do zem&iacute; uvnitř EHP nebo do jin&eacute; země mimo EHP. Pokud např&iacute;klad hled&aacute;te poskytovatele služeb v USA, může b&yacute;t nutn&eacute; va&scaron;e &uacute;daje předat do USA.</span></p>
             <p><span style="color: #000000;">Přestože Velk&aacute; Brit&aacute;nie již nen&iacute; souč&aacute;st&iacute; EHP, budeme va&scaron;e &uacute;daje i nad&aacute;le uchov&aacute;vat se stejnou &uacute;rovn&iacute; ochrany jako v době, kdy jsme byli souč&aacute;st&iacute; EHP. To znamen&aacute;, že při jak&eacute;mkoli před&aacute;n&iacute; va&scaron;ich &uacute;dajů mimo Velkou Brit&aacute;nii budeme i nad&aacute;le podnikat kroky k zaji&scaron;těn&iacute; toho, aby byly chr&aacute;něny na stejn&eacute; &uacute;rovni, jak&aacute; se vztahuje na před&aacute;v&aacute;n&iacute; &uacute;dajů mimo EHP.</span></p>
             <p><span style="color: #000000;">Na někter&eacute; země se vztahuje rozhodnut&iacute; Evropsk&eacute; komise o přiměřenosti, což znamen&aacute;, že jsou považov&aacute;ny za země s odpov&iacute;daj&iacute;c&iacute; &uacute;rovn&iacute; ochrany &uacute;dajů. &Uacute;daje budeme nad&aacute;le před&aacute;vat pouze těmto zem&iacute;m a na tomto z&aacute;kladě.</span></p>
             <p><span style="color: #000000;">Jin&eacute; země nemaj&iacute; stejnou &uacute;roveň pr&aacute;vn&iacute; ochrany jako země EHP nebo země, v nichž plat&iacute; rozhodnut&iacute; o přiměřenosti. Pokud va&scaron;e &uacute;daje před&aacute;v&aacute;me t&iacute;mto způsobem, podnikneme kroky k tomu, abychom zajistili, že budou chr&aacute;něny na stejn&eacute; &uacute;rovni jako v EHP. To může zahrnovat např&iacute;klad přijet&iacute; standardn&iacute;ch smluvn&iacute;ch doložek EU.</span></p>
             <h3><span style="color: #000000;"><strong>UKL&Aacute;D&Aacute;N&Iacute; &Uacute;DAJŮ</strong></span></h3>
             <p><span style="color: #000000;">Ř&iacute;d&iacute;me se z&aacute;sadami o uchov&aacute;v&aacute;n&iacute; &uacute;dajů, kter&eacute; jasně stanovuj&iacute;, jak dlouho a z jak&yacute;ch důvodů &uacute;daje uchov&aacute;v&aacute;me.</span></p>
             <p><span style="color: #000000;">Va&scaron;e &uacute;daje uchov&aacute;v&aacute;me pouze tak dlouho, dokud je to nezbytn&eacute; pro splněn&iacute; &uacute;čelu/&uacute;čelů, pro kter&eacute; byly z&iacute;sk&aacute;ny, to znamen&aacute; tak, jak je stanoveno v těchto z&aacute;sad&aacute;ch o ochraně osobn&iacute;ch &uacute;dajů.</span></p>
             <p><span style="color: #000000;">Např&iacute;klad:</span></p>
             <ul>
                 <li><span style="color: #000000;">Va&scaron;i ž&aacute;dost o cenovou nab&iacute;dku si ponech&aacute;me po dobu 6 měs&iacute;ců od jej&iacute;ho pod&aacute;n&iacute;, abychom mohli vyře&scaron;it př&iacute;padn&eacute; dotazy od poskytovatelů služeb, pokud byly vzneseny.</span></li>
                 <li><span style="color: #000000;">Pokud jste si neodhl&aacute;sili odběr na&scaron;ich marketingov&yacute;ch materi&aacute;lů, ponech&aacute;me si va&scaron;e &uacute;daje a budeme v&aacute;m pos&iacute;lat e-maily, a to doby, kdy si po dobu 6 po sobě jdouc&iacute;ch měs&iacute;ců na&scaron;e e-maily nebudete č&iacute;st.</span></li>
             </ul>
             <p><span style="color: #000000;">Po uplynut&iacute; v&yacute;&scaron;e uveden&yacute;ch obdob&iacute; &uacute;daje nepoužijeme. Budeme je v&scaron;ak ha&scaron;ovat/anonymizovat a uchov&aacute;vat je po dobu dal&scaron;&iacute;ch 3 let za &uacute;čelem dodržov&aacute;n&iacute; předpisů.</span></p>
             <h3><span style="color: #000000;"><strong>PR&Aacute;VN&Iacute; ODŮVODNĚN&Iacute;</strong></span></h3>
             <p><span style="color: #000000;">Tyto z&aacute;sady ochrany osobn&iacute;ch &uacute;dajů (v&yacute;&scaron;e) stanovuj&iacute;, jak&yacute;m způsobem va&scaron;e &uacute;daje použ&iacute;v&aacute;me. Podle GDPR mus&iacute; spr&aacute;vci při každ&eacute; aktivitě v r&aacute;mci zpracov&aacute;n&iacute; &uacute;dajů, kterou prov&aacute;děj&iacute;, postupovat podle platn&eacute;ho pr&aacute;vn&iacute;ho z&aacute;kladu. V t&eacute;to č&aacute;sti je uveden n&aacute;&scaron; pr&aacute;vn&iacute; z&aacute;klad pro každou aktivitu.</span></p>
             <table style="width: 100%;">
                 <tbody>
                 <tr bgcolor="#e6e6fa">
                     <th style="text-align: center;"><span style="color: #000000;">Aktivita</span></th>
                     <th style="text-align: center;"><span style="color: #000000;">Pr&aacute;vn&iacute; z&aacute;klad podle nař&iacute;zen&iacute; GDPR</span></th>
                     <th style="text-align: center;"><span style="color: #000000;"><strong>Va&scaron;e pr&aacute;va</strong></span></th>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Spojit v&aacute;s s př&iacute;slu&scaron;n&yacute;mi poskytovateli služeb, a to i ve vztahu k někter&yacute;m produktům souvisej&iacute;c&iacute;m se zdrav&iacute;m</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Souhlas</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Pro stažen&iacute; souhlasu, jak je vysvětleno n&iacute;že</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Automatizovan&eacute; rozhodov&aacute;n&iacute; vysvětlen&eacute; v&yacute;&scaron;e</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Opr&aacute;vněn&eacute; z&aacute;jmy</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">M&aacute;te pr&aacute;vo pod&aacute;vat n&aacute;mitky proti zpracov&aacute;n&iacute; na z&aacute;kladě opr&aacute;vněn&yacute;ch z&aacute;jmů</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Použ&iacute;v&aacute;n&iacute; a sd&iacute;len&iacute; IP adres</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Opr&aacute;vněn&eacute; z&aacute;jmy</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">M&aacute;te pr&aacute;vo pod&aacute;vat n&aacute;mitky proti zpracov&aacute;n&iacute; na z&aacute;kladě opr&aacute;vněn&yacute;ch z&aacute;jmů</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Pro odesl&aacute;n&iacute; e-mailu t&yacute;kaj&iacute;c&iacute;ho se č&aacute;stečně vyplněn&eacute;ho webov&eacute;ho formul&aacute;ře</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Opr&aacute;vněn&eacute; z&aacute;jmy</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">M&aacute;te pr&aacute;vo pod&aacute;vat n&aacute;mitky proti zpracov&aacute;n&iacute; na z&aacute;kladě opr&aacute;vněn&yacute;ch z&aacute;jmů</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Kontaktov&aacute;n&iacute; za &uacute;čelem potvrzen&iacute; va&scaron;ich &uacute;dajů nebo požadavků</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Souhlas</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Pro stažen&iacute; souhlasu, jak je vysvětleno n&iacute;že</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Kontaktov&aacute;n&iacute; za &uacute;čelem domluven&iacute; si schůzky</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Souhlas</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Pro stažen&iacute; souhlasu, jak je vysvětleno n&iacute;že</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Sd&iacute;len&iacute; nahr&aacute;vek hovorů s poskytovateli služeb</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Opr&aacute;vněn&eacute; z&aacute;jmy</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Pro pod&aacute;n&iacute; n&aacute;mitky proti zpracov&aacute;n&iacute; na z&aacute;kladě opr&aacute;vněn&yacute;ch z&aacute;jmů</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Pro zas&iacute;l&aacute;n&iacute; e-mailů nebo SMS zpr&aacute;v o podobn&yacute;ch produktech a služb&aacute;ch</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Opr&aacute;vněn&eacute; z&aacute;jmy*</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Pro pod&aacute;n&iacute; n&aacute;mitky proti zpracov&aacute;n&iacute; na z&aacute;kladě opr&aacute;vněn&yacute;ch z&aacute;jmů. M&aacute;te rovněž pr&aacute;vo pod&aacute;vat n&aacute;mitky proti př&iacute;m&eacute;mu marketingu</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Za &uacute;čelem vytvořen&iacute; uživatelsk&eacute;ho a obdobn&eacute;ho/podobn&eacute;ho publika</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Opr&aacute;vněn&eacute; z&aacute;jmy</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">M&aacute;te pr&aacute;vo pod&aacute;vat n&aacute;mitky proti zpracov&aacute;n&iacute; na z&aacute;kladě opr&aacute;vněn&yacute;ch z&aacute;jmů</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Př&iacute;jem &uacute;dajů od poskytovatelů služeb</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Opr&aacute;vněn&eacute; z&aacute;jmy</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">M&aacute;te pr&aacute;vo pod&aacute;vat n&aacute;mitky proti zpracov&aacute;n&iacute; na z&aacute;kladě opr&aacute;vněn&yacute;ch z&aacute;jmů</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Za &uacute;čelem odesl&aacute;n&iacute; e-mailu nebo SMS s v&iacute;ce informacemi o produktech nebo služb&aacute;ch, o kter&eacute; jste projevili z&aacute;jem</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Souhlas</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Chcete-li svůj souhlas st&aacute;hnout, klikněte na odkaz pro odhl&aacute;&scaron;en&iacute; odběru v e-mailu, př&iacute;padně SMS zpr&aacute;vě.&nbsp;</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Aby se v&aacute;m l&eacute;pe pracovalo s aplikac&iacute; a pro přiřazov&aacute;n&iacute; marketingov&eacute;ho obsahu</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Opr&aacute;vněn&eacute; z&aacute;jmy</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">M&aacute;te pr&aacute;vo pod&aacute;vat n&aacute;mitky proti zpracov&aacute;n&iacute; na z&aacute;kladě opr&aacute;vněn&yacute;ch z&aacute;jmů</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">K ověřen&iacute; toho, zda je odesl&aacute;n&iacute; webov&eacute;ho formul&aacute;ře skutečnou ž&aacute;dost&iacute; (pouze v př&iacute;padě, že se va&scaron;e ž&aacute;dost t&yacute;k&aacute; finančn&iacute;ho produktu nebo finančn&iacute;ch služeb)</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Opr&aacute;vněn&eacute; z&aacute;jmy</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">M&aacute;te pr&aacute;vo pod&aacute;vat n&aacute;mitky proti zpracov&aacute;n&iacute; na z&aacute;kladě opr&aacute;vněn&yacute;ch z&aacute;jmů</span></p>
                     </td>
                 </tr>
                 </tbody>
             </table>
             <p><span style="color: #000000;"><em>*Podle nař&iacute;zen&iacute; o ochraně soukrom&iacute; a elektronick&yacute;ch komunikac&iacute;ch (směrnice ES) z roku 2003 (PECR) odes&iacute;l&aacute;me takov&eacute; e-maily na z&aacute;kladě st&aacute;vaj&iacute;c&iacute; z&aacute;kaznick&eacute; v&yacute;jimky (soft opt-in).</em></span></p>
             <p><span style="color: #000000;">V každ&eacute;m př&iacute;padě, kdy jsme identifikovali opr&aacute;vněn&eacute; z&aacute;jmy jako pr&aacute;vn&iacute; z&aacute;klad pro na&scaron;e zpracov&aacute;n&iacute;, jsme provedli posouzen&iacute; opr&aacute;vněn&yacute;ch z&aacute;jmů.</span></p>
             <h3><span style="color: #000000;"><strong>VA&Scaron;E PR&Aacute;VA</strong></span></h3>
             <p><span style="color: #000000;">Va&scaron;e pr&aacute;va určuje země, ve kter&eacute; s&iacute;dl&iacute;te. Pokud m&aacute;te bydli&scaron;tě v Evropsk&eacute;m hospod&aacute;řsk&eacute;m prostoru nebo ve Velk&eacute; Brit&aacute;nii, m&aacute;te podle GDPR v souvislosti se sv&yacute;mi osobn&iacute;mi &uacute;daji určit&aacute; pr&aacute;va. Ve vztahu k na&scaron;im str&aacute;nk&aacute;m a služb&aacute;m, kter&eacute; poskytujeme, jsou tato pr&aacute;va uvedena v tabulce v&yacute;&scaron;e. Tato pr&aacute;va a va&scaron;e dal&scaron;&iacute; pr&aacute;va vysvětl&iacute;me podrobněji n&iacute;že.</span></p>
             <p><span style="color: #000000;"><strong>Pr&aacute;vo na př&iacute;stup</strong> &ndash; Kdykoli m&aacute;te pr&aacute;vo pož&aacute;dat n&aacute;s o kopii osobn&iacute;ch &uacute;dajů, kter&eacute; o v&aacute;s uchov&aacute;v&aacute;me, a zkontrolovat, zda je zpracov&aacute;v&aacute;me v souladu se z&aacute;konem.</span></p>
             <p><span style="color: #000000;"><strong>Pr&aacute;vo na opravu</strong> &ndash; Pokud osobn&iacute; &uacute;daje, kter&eacute; o v&aacute;s uchov&aacute;v&aacute;me, nejsou přesn&eacute; nebo aktu&aacute;ln&iacute; a vyžaduj&iacute; změnu nebo opravu, m&aacute;te pr&aacute;vo na opravu nebo doplněn&iacute; &uacute;dajů.</span></p>
             <p><span style="color: #000000;"><strong>Pr&aacute;vo na v&yacute;maz</strong> &ndash; Za určit&yacute;ch okolnost&iacute; m&aacute;te pr&aacute;vo požadovat v&yacute;maz osobn&iacute;ch &uacute;dajů, kter&yacute;mi o v&aacute;s disponujeme (např. pokud již nejsou nezbytn&eacute; pro &uacute;čely, pro něž byly shromažďov&aacute;ny nebo zpracov&aacute;v&aacute;ny).</span></p>
             <p><span style="color: #000000;"><strong>Pr&aacute;vo vzn&eacute;st n&aacute;mitku nebo omezit zpracov&aacute;n&iacute;</strong> &ndash; Za určit&yacute;ch okolnost&iacute; m&aacute;te pr&aacute;vo vzn&eacute;st n&aacute;mitku proti tomu, že va&scaron;e osobn&iacute; &uacute;daje zpracov&aacute;v&aacute;me. Pokud např&iacute;klad zpracov&aacute;v&aacute;me va&scaron;e &uacute;daje na z&aacute;kladě na&scaron;ich opr&aacute;vněn&yacute;ch z&aacute;jmů a pro takov&eacute; zpracov&aacute;n&iacute; neexistuj&iacute; ž&aacute;dn&eacute; přesvědčiv&eacute; opr&aacute;vněn&eacute; důvody, kter&eacute; by převažovaly nad va&scaron;imi pr&aacute;vy a z&aacute;jmy.</span></p>
             <p><span style="color: #000000;"><strong>Pr&aacute;vo na přenositelnost &uacute;dajů</strong> &ndash; V někter&yacute;ch př&iacute;padech m&aacute;te pr&aacute;vo obdržet ve&scaron;ker&eacute; osobn&iacute; &uacute;daje, kter&eacute; o v&aacute;s uchov&aacute;v&aacute;me, ve strukturovan&eacute;m, běžně použ&iacute;van&eacute;m a strojově čiteln&eacute;m form&aacute;tu.</span></p>
             <p><span style="color: #000000;"><strong>Pr&aacute;vo odvolat souhlas</strong> &ndash; Za omezen&yacute;ch okolnost&iacute;, kdy jste poskytli souhlas se shromažďov&aacute;n&iacute;m, zpracov&aacute;n&iacute;m a před&aacute;v&aacute;n&iacute;m osobn&iacute;ch &uacute;dajů pro konkr&eacute;tn&iacute; &uacute;čel, m&aacute;te pr&aacute;vo svůj souhlas s t&iacute;mto konkr&eacute;tn&iacute;m zpracov&aacute;n&iacute;m kdykoli odvolat. Toto pr&aacute;vo můžete uplatnit v na&scaron;em <a style="color: #000000;" href="https://preferences.safe-click.net/">Centru preferenc&iacute;</a>.</span></p>
             <p><span style="color: #000000;"><strong>Uplatňov&aacute;n&iacute; va&scaron;ich pr&aacute;v</strong></span></p>
             <p><span style="color: #000000;">Jak je vysvětleno v&yacute;&scaron;e, sv&eacute; pr&aacute;vo na odvol&aacute;n&iacute; souhlasu můžete uplatnit v na&scaron;em Centru preferenc&iacute; <a style="color: #000000;" href="https://preferences.safe-click.net/">https://preferences.safe-click.net/</a>.</span></p>
             <p><span style="color: #000000;">Přejete-li si uplatnit jak&aacute;koliv dal&scaron;&iacute; pr&aacute;va podle GDPR nebo pokud si přejete zjistit, jak va&scaron;e &uacute;daje použ&iacute;v&aacute;me, napi&scaron;te n&aacute;m na adresu dataprotection@mvfglobal.com. Př&iacute;padně n&aacute;m můžete napsat na v&yacute;&scaron;e uvedenou adresu nebo n&aacute;s informovat telefonicky.</span></p>
             <p><span style="color: #000000;">Na&scaron;&iacute;m hlavn&iacute;m dozorč&iacute;m org&aacute;nem pro zpracov&aacute;n&iacute; &uacute;dajů stanoven&eacute; v těchto z&aacute;sad&aacute;ch osobn&iacute;ch &uacute;dajů je &Uacute;řad komisaře pro informace Velk&eacute; Brit&aacute;nie (ICO). Pokud nejste spokojeni s t&iacute;m, jak va&scaron;e &uacute;daje zpracov&aacute;v&aacute;me, m&aacute;te pr&aacute;vo podat u ICO st&iacute;žnost.</span></p>
             <p><span style="color: #000000;">Pokud m&aacute;te s&iacute;dlo mimo Velkou Brit&aacute;nii nebo chcete podat st&iacute;žnost t&yacute;kaj&iacute;c&iacute; se na&scaron;ich činnost&iacute; mimo Velkou Brit&aacute;nii, můžete tak učinit u jin&eacute;ho dozorov&eacute;ho org&aacute;nu. Seznam př&iacute;slu&scaron;n&yacute;ch org&aacute;nů najdete <a style="color: #000000;" href="http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080">zde</a>.</span></p>
             <p><span style="color: #000000;"><strong>PR&Aacute;VA NA SOUKROM&Iacute; PLATN&Aacute; V KALIFORNII</strong></span></p>
             <p><span style="color: #000000;">Tato č&aacute;st obsahuje bliž&scaron;&iacute;informace o osobn&iacute;ch &uacute;daj&iacute;ch, kter&eacute; shromažďujeme o kalifornsk&yacute;ch spotřebitel&iacute;ch, a o pr&aacute;vech, jež stanovuje Kalifornsk&yacute; z&aacute;kon o ochraně spotřebitele (d&aacute;le<strong>jen &bdquo;CCPA&ldquo;</strong>).</span></p>
             <p><span style="color: #000000;">V souvislosti se sv&yacute;mi &uacute;daji m&aacute;te určit&aacute; pr&aacute;va, včetně pr&aacute;va na v&yacute;maz osobn&iacute;ch &uacute;dajů a pr&aacute;va vzn&eacute;st n&aacute;mitku proti prodeji va&scaron;ich osobn&iacute;ch &uacute;dajů třet&iacute;m stran&aacute;m. Podrobnosti o osobn&iacute;ch &uacute;daj&iacute;ch, kter&eacute; jsme z&iacute;skali v posledn&iacute;ch 12 měs&iacute;c&iacute;ch, včetně kategori&iacute; zdrojů, naleznete v č&aacute;sti &bdquo;Jak&eacute; &uacute;daje shromažďujeme a jak je shromažďujeme&ldquo; uveden&eacute; v&yacute;&scaron;e. Tyto &uacute;daje z&iacute;sk&aacute;v&aacute;me pro &uacute;čely vymezen&eacute; v&yacute;&scaron;e v č&aacute;sti &bdquo;Jak va&scaron;e &uacute;daje použ&iacute;v&aacute;me&ldquo;. Tyto &uacute;daje sd&iacute;l&iacute;me s kategoriemi třet&iacute;ch stran popsan&yacute;mi v t&eacute;že č&aacute;sti. Osobn&iacute; &uacute;daje, kter&eacute; shromažďujeme, neprod&aacute;v&aacute;me (jak je stanoveno v CCPA). Soubory cookie třet&iacute;ch stran použ&iacute;v&aacute;me pro na&scaron;e reklamn&iacute; &uacute;čely, jak je pops&aacute;no v č&aacute;sti Soubory cookie v&yacute;&scaron;e a v tabulce n&iacute;že.</span></p>
             <p><span style="color: #000000;">Krom určit&yacute;ch omezen&iacute; stanovuje CCPA pro kalifornsk&eacute; spotřebitele pr&aacute;vo vyž&aacute;dat si v&iacute;ce informac&iacute; o kategori&iacute;ch nebo konkr&eacute;tn&iacute;ch shromažďovan&yacute;ch osobn&iacute;ch &uacute;daj&iacute;ch, např&iacute;klad o tom, jak tyto informace použ&iacute;v&aacute;me a zveřejňujeme, a osobn&iacute; &uacute;daje vymazat.</span></p>
             <p><span style="color: #000000;">Kaliforn&scaron;t&iacute; spotřebitel&eacute; mohou podat ž&aacute;dost v souladu se sv&yacute;mi pr&aacute;vy stanoven&yacute;mi CCPA na adrese <a style="color: #000000;" href="mailto:protection@mvfglobal.com">protection@mvfglobal.com</a>. V&aacute;&scaron; požadavek ověř&iacute;me pomoc&iacute; informac&iacute;, kter&eacute; jste n&aacute;m již dř&iacute;ve poskytli, včetně va&scaron;&iacute; e-mailov&eacute; adresy.</span></p>
             <p><span style="color: #000000;">Přečtěte si pros&iacute;m na&scaron;e &uacute;pln&eacute; z&aacute;sady ochrany osobn&iacute;ch &uacute;dajů pro kalifornsk&eacute; spotřebitele uveden&eacute; na adrese https://www2.mvfglobal.com/privacy_policy/55baa/71f55</span></p>
             <h3><span style="color: #000000;"><strong>JAK N&Aacute;S KONTAKTOVAT</strong></span></h3>
             <p><span style="color: #000000;">V př&iacute;padě jak&yacute;chkoliv ot&aacute;zek ohledně těchto z&aacute;sad ochrany osobn&iacute;ch &uacute;dajů nebo jak&yacute;chkoliv ot&aacute;zek t&yacute;kaj&iacute;c&iacute;ch se ochrany osobn&iacute;ch &uacute;dajů či soukrom&iacute; obecně n&aacute;s kontaktujte na adrese dataprotection@mvfglobal.com.</span></p>
             <p><span style="color: #000000;">Můžete n&aacute;m tak&eacute; napsat na adresu uvedenou v&yacute;&scaron;e v č&aacute;sti &bdquo;O n&aacute;s&ldquo;.</span></p>
             <p><span style="color: #000000;"><strong>N&aacute;&scaron; z&aacute;stupce v EU</strong></span></p>
             <p><span style="color: #000000;">Jelikož již nem&aacute;me provozovnu v Evropsk&eacute; unii, jmenovali jsme z&aacute;stupce se s&iacute;dlem v Nizozemsku. N&iacute;že uv&aacute;d&iacute;me podrobnosti o na&scaron;em z&aacute;stupci v EU: Software Vergelijken B.V. se s&iacute;dlem v Heresingel 4B, 9711 ES Groningen, Nizozemsko.&nbsp;</span></p>
             <p><span style="color: #000000;">Pokud m&aacute;te s&iacute;dlo v EU a m&aacute;te jak&eacute;koli ot&aacute;zky t&yacute;kaj&iacute;c&iacute; se těchto z&aacute;sad ochrany osobn&iacute;ch &uacute;dajů nebo jak&eacute;koli ot&aacute;zky t&yacute;kaj&iacute;c&iacute; se ochrany &uacute;dajů či soukrom&iacute; obecně, můžete kontaktovat na&scaron;eho z&aacute;stupce v EU př&iacute;mo na adrese dataprotection@mvfglobal.com.</span></p>
             <h3><span style="color: #000000;"><strong>SOUBORY COOKIE, KTER&Eacute; POUŽ&Iacute;V&Aacute;ME, A &Uacute;ČELY, ZA JAK&Yacute;MI JE POUŽ&Iacute;V&Aacute;ME</strong></span></h3>
             <table style="width: 100%;">
                 <tbody>
                 <tr bgcolor="#f1c67a">
                     <th style="text-align: center;"><span style="color: #000000;">Soubor cookie</span></th>
                     <th style="text-align: center;"><span style="color: #000000;"><strong>N&aacute;zev</strong></span></th>
                     <th style="text-align: center;"><span style="color: #000000;"><strong>&Uacute;čel</strong></span></th>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Universal Analytics (Google)</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">_ga _gali _gat_UA-1036645-1 _gid</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie shromažďuj&iacute; informace o tom, jak n&aacute;v&scaron;těvn&iacute;ci využ&iacute;vaj&iacute; na&scaron;e webov&eacute; str&aacute;nky. Informace použ&iacute;v&aacute;me k sestavov&aacute;n&iacute; přehledů a k vylep&scaron;ov&aacute;n&iacute;. Tyto soubory cookie shromažďuj&iacute; informace anonymně, včetně toho, odkud n&aacute;v&scaron;těvn&iacute;ci na danou internetovou str&aacute;nku při&scaron;li a kter&eacute; podstr&aacute;nky nav&scaron;t&iacute;vili. Chcete-li tento typ zpracov&aacute;n&iacute; odm&iacute;tnout: <a style="color: #000000;" href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Taboola Retargeting</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie se použ&iacute;vaj&iacute; k přizpůsoben&iacute; reklam Taboola podle dř&iacute;ve nav&scaron;t&iacute;ven&yacute;ch str&aacute;nek. Pokud jste např&iacute;klad nav&scaron;t&iacute;vili n&aacute;&scaron; web dř&iacute;ve, mohou se v&aacute;m zobrazit dal&scaron;&iacute; reklamy souvisej&iacute;c&iacute; s dan&yacute;m produktem nebo službami. Taboola je reklamn&iacute; s&iacute;ť, kter&aacute; zobrazuje sponzorovan&yacute; obsah, a to obvykle jako odkazy pod čl&aacute;nkem na hlavn&iacute;ch zpravodajsk&yacute;ch str&aacute;nk&aacute;ch.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Outbrain Retargeting</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie se použ&iacute;vaj&iacute; k přizpůsoben&iacute; reklam Outbrain podle dř&iacute;ve prohl&iacute;žen&yacute;ch str&aacute;nek. Pokud jste např&iacute;klad nav&scaron;t&iacute;vili n&aacute;&scaron; web dř&iacute;ve, mohou se v&aacute;m zobrazit dal&scaron;&iacute; reklamy souvisej&iacute;c&iacute; s dan&yacute;m produktem nebo službami. Outbrain je reklamn&iacute; s&iacute;ť, kter&aacute; zobrazuje sponzorovan&yacute; obsah, a to obvykle jako odkazy pod čl&aacute;nkem na hlavn&iacute;ch zpravodajsk&yacute;ch str&aacute;nk&aacute;ch.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Adwords Retargeting (Google)</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie se použ&iacute;vaj&iacute; k &uacute;pravě c&iacute;len&iacute; na&scaron;ich placen&yacute;ch bannerov&yacute;ch a kontextov&yacute;ch reklam, aby zobrazovaly reklamn&iacute; obsah uživatelům, kteř&iacute; již dř&iacute;ve nav&scaron;t&iacute;vili na&scaron;e str&aacute;nky a/nebo na na&scaron;ich str&aacute;nk&aacute;ch něco dělali, např&iacute;klad vyplnili formul&aacute;ř. Neobsahuj&iacute; ž&aacute;dn&eacute; osobn&iacute; &uacute;daje.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">DoubleClick Retargeting</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie se použ&iacute;vaj&iacute; k &uacute;pravě c&iacute;len&iacute; na&scaron;&iacute; bannerov&eacute; reklamy tak, aby zobrazovala reklamn&iacute; obsah uživatelům, kteř&iacute; již dř&iacute;ve nav&scaron;t&iacute;vili na&scaron;e str&aacute;nky a/nebo na na&scaron;ich str&aacute;nk&aacute;ch něco dělali, např&iacute;klad vyplnili formul&aacute;ř. Neobsahuj&iacute; ž&aacute;dn&eacute; osobn&iacute; &uacute;daje.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Taboola Conversion Tracking</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie měř&iacute; konverze reklam a optimalizuj&iacute; v&yacute;daje na reklamn&iacute; kampaně poskytovan&eacute; serverem Taboola.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Outbrain Conversion Tracking</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie měř&iacute; konverze reklam a optimalizuj&iacute; v&yacute;daje na reklamn&iacute; kampaně poskytovan&eacute; serverem Outbrain.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Yahoo Conversion Tracking</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie měř&iacute; konverze reklam a optimalizuj&iacute; v&yacute;daje na reklamn&iacute; kampaně poskytovan&eacute; serverem Yahoo Gemini.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Zemanta Conversion Tracking</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie měř&iacute; konverze reklam a optimalizuj&iacute; v&yacute;daje na reklamn&iacute; kampaně poskytovan&eacute; serverem Zemanta.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Ligatus/LiquidM Conversion Tracking</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie měř&iacute; konverze reklam a optimalizuj&iacute; v&yacute;daje na reklamn&iacute; kampaně poskytovan&eacute; servery Ligatus a LiquidM.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Plista Conversion Tracking</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie měř&iacute; konverze reklam a optimalizuj&iacute; v&yacute;daje na reklamn&iacute; kampaně poskytovan&eacute; serverem Plista.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Revcontent Conversion Tracking</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie měř&iacute; konverze reklam a optimalizuj&iacute; v&yacute;daje na reklamn&iacute; kampaně poskytovan&eacute; serverem Revcontent.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">EngageYa Conversion Tracking</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie měř&iacute; konverze reklam a optimalizuj&iacute; v&yacute;daje na reklamn&iacute; kampaně poskytovan&eacute; serverem EngageYa.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Adwords Conversion Tracking</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie zji&scaron;ťuj&iacute; proveden&iacute; nějak&eacute;ho &uacute;konu uživatelem na dan&eacute; internetov&eacute; str&aacute;nce jako např. vyplněn&iacute; formul&aacute;ře. Neobsahuj&iacute; ž&aacute;dn&eacute; osobn&iacute; &uacute;daje.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Bing Conversion Tracking</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">UET Tag</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie zji&scaron;ťuj&iacute; proveden&iacute; nějak&eacute;ho &uacute;konu uživatelem na dan&eacute; internetov&eacute; str&aacute;nce jako např. vyplněn&iacute; formul&aacute;ře. Neobsahuj&iacute; ž&aacute;dn&eacute; osobn&iacute; &uacute;daje.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">DoubleClick Conversion Tracking</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie zji&scaron;ťuj&iacute; proveden&iacute; nějak&eacute;ho &uacute;konu uživatelem na dan&eacute; internetov&eacute; str&aacute;nce jako např. vyplněn&iacute; formul&aacute;ře. Neobsahuj&iacute; ž&aacute;dn&eacute; osobn&iacute; &uacute;daje.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Facebook Pixel</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie měř&iacute; konverze reklam a optimalizuj&iacute; a vytv&aacute;řej&iacute; publikum pro reklamn&iacute; kampaně v s&iacute;ti Facebook Chcete-li tento typ zpracov&aacute;n&iacute; odm&iacute;tnout: <a style="color: #000000;" href="https://www.facebook.com/policies/cookies/">https://www.facebook.com/policies/cookies/</a>.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Nanigans JavaScript Pixel</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie měř&iacute; konverze reklam a optimalizuj&iacute; reklamn&iacute; kampaně spravovan&eacute; prostřednictv&iacute;m Nanigans. Neobsahuj&iacute; ž&aacute;dn&eacute; osobn&iacute; &uacute;daje. Chcete-li tento typ zpracov&aacute;n&iacute; odm&iacute;tnout: <a style="color: #000000;" href="http://www.nanigans.com/privacy-policy/">http://www.nanigans.com/privacy-policy/</a></span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Twitter Pixel</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie měř&iacute; konverze reklam a optimalizuj&iacute; reklamn&iacute; kampaně zobrazovan&eacute; na s&iacute;ti Twitter. Chcete-li tento typ zpracov&aacute;n&iacute; odm&iacute;tnout: <a style="color: #000000;" href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads">https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads</a></span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">LinkedIn Pixel</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie měř&iacute; konverze reklam a optimalizuj&iacute; a vytv&aacute;řej&iacute; publikum pro reklamn&iacute; kampaně v s&iacute;ti LinkedIn Chcete-li tento typ zpracov&aacute;n&iacute; odm&iacute;tnout: <a style="color: #000000;" href="https://www.linkedin.com/legal/cookie-policy">https://www.linkedin.com/legal/cookie-policy</a></span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Pinterest Tag</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie se použ&iacute;vaj&iacute; ke sledov&aacute;n&iacute; konverz&iacute;, optimalizaci a vytv&aacute;řen&iacute; publika pro osloven&iacute; z&aacute;kazn&iacute;ků v s&iacute;ti Pinterest. Chcete-li tento typ zpracov&aacute;n&iacute; odm&iacute;tnout: <a style="color: #000000;" href="https://help.pinterest.com/en/articles/personalized-ads-pinterest">https://help.pinterest.com/en/articles/personalized-ads-pinterest</a></span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Skimlinks</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Na&scaron;e webov&eacute; str&aacute;nky dost&aacute;vaj&iacute; zaplacenou provizi z prodeje produktů nebo služeb, o kter&yacute;ch p&iacute;&scaron;eme. V někter&yacute;ch př&iacute;padech využ&iacute;v&aacute;me třet&iacute; strany, aby tato partnerstv&iacute; zprostředkovaly. Chcete-li tento typ zpracov&aacute;n&iacute; odm&iacute;tnout: <a style="color: #000000;" href="https://skimlinks.com/privacy-policy#users">https://skimlinks.com/privacy-policy#users</a></span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Amazon Associates</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie sleduj&iacute; n&aacute;v&scaron;těvy internetov&eacute;ho obchodu Amazon přes odkaz na jin&yacute;ch str&aacute;nk&aacute;ch, za kter&eacute; můžeme z&iacute;skat provizi z př&iacute;padn&yacute;ch n&aacute;kupů.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">Adsense</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">AdSense využ&iacute;v&aacute; soubory cookie k vylep&scaron;ov&aacute;n&iacute; reklamy t&iacute;m, že např. zobrazuje c&iacute;len&eacute; reklamy a omezuje zobrazov&aacute;n&iacute; reklamy, kterou uživatel již viděl. Tyto soubory cookie neobsahuj&iacute; ž&aacute;dn&eacute; osobn&iacute; identifikačn&iacute; &uacute;daje.</span></p>
                     </td>
                 </tr>
                 <tr>
                     <td>
                         <p><span style="color: #000000;">VWO</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">_vwo</span></p>
                     </td>
                     <td>
                         <p><span style="color: #000000;">Tyto soubory cookie se použ&iacute;vaj&iacute; k optimalizaci na&scaron;ich str&aacute;nek, jelikož prov&aacute;děj&iacute; testy a měřen&iacute; konverz&iacute; uživatelů. Rovněž se použ&iacute;vaj&iacute; ke sledov&aaaacute;n&iacute; informac&iacute; o uživateli při n&aacute;v&scaron;těvě webu (např. informac&iacute; o prohl&iacute;žeči a zař&iacute;zen&iacute;).
         </section>-->
    </section>
</template>

<script>

    export default {
        name: 'Gdpr',
        components: {}
    }
</script>
<style lang="scss">

</style>
