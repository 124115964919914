import Vue from 'vue'
import App from './App.vue'
import router from './router'

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";


VueFormGenerator.validators.resources.fieldIsRequired = "Prosím vyplňte pole!";
VueFormGenerator.validators.resources.invalidFormat = "Prosím vyplňte spravný formát!";

VueFormGenerator.validators.resources.numberTooSmall = "Prosím vyplňte vetší číslo! Minimum= {0}";
VueFormGenerator.validators.resources.numberTooBig = "Prosím vyplňte menší číslo! Maximum= {0}";
VueFormGenerator.validators.resources.invalidNumber = "Neplatné číslo!";
VueFormGenerator.validators.resources.invalidInteger = "The value is not an integer!";

VueFormGenerator.validators.resources.textTooSmall = "Text je příliš kratký! Nynější= {0}; Minimum= {1}";
VueFormGenerator.validators.resources.textTooBig = "Text je příliš dlouhý! Nynější= {0}; Maximum= {1}";
VueFormGenerator.validators.resources.thisNotText = "Pole musí obsahovat pouze text!";


VueFormGenerator.validators.resources.selectMinItems = "Select minimum {0} items!";
VueFormGenerator.validators.resources.selectMaxItems = "Select maximum {0} items!";

VueFormGenerator.validators.resources.invalidDate = "Prosím vyplňte platné datum";
VueFormGenerator.validators.resources.dateIsEarly = "The date is too early! Current= {0}; Minimum= {1}";
VueFormGenerator.validators.resources.dateIsLate = "The date is too late! Current= {0}; Maximum= {1}";

VueFormGenerator.validators.resources.invalidEmail = "Neplatný email!";

Vue.use(VueFormGenerator);

import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import fieldMagicSelect from "./components/fieldMagicSelect";

Vue.component("fieldMagicSelect", fieldMagicSelect);

Vue.use(VueFormWizard);

import AnimateCSS from 'animate.css';

Vue.use(AnimateCSS);

import {config} from './config'
import VueGtm from "vue-gtm";

Vue.use(VueGtm, {
    id: config.gtm,
    enabled: true,
    debug: false,
    vueRouter: router
});

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import './style.scss'

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
