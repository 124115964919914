<template>
  <section>
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-12 text-center mb-5">
          <h1>Bezplatná cenová nabídka na solární{{ '\xa0' }}elektrárnu</h1>
        </div>
        <div class="col-md-8">
          <div class="card shadow">
            <div class="card-body">
              <WizardForm/>
            </div>
          </div>
          <div class="additional-info">
            <div class="card-body">
              <h3>Nechte si vystavit nabídku ve 3 jednoduchých krocích</h3>
              <hr>
              <ol>
                <li>Vyplňte náš stručný formulář – zabere vám to necelou minutu</li>
                <li>Nechte si vypracovat bezplatnou nabídku od partnera, který splní vaše požadavky</li>
                <li>Obdržíte speciální nabídku od našeho partnera</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import WizardForm from '@/components/WizardForm.vue'

export default {
  name: 'Home',
  components: {
    WizardForm,
  }
}
</script>

