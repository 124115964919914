<template>
    <section>
        <div id="conversion-value" class="d-none">200</div>
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="row pb-5">
                                <div class="d-none d-sm-block col-sm-3">
                                    <lottie class="img-fluid"
                                            :options="lottieIconFinal"
                                            :height="150"
                                            :width="150"/>
                                </div>
                                <div class="col-12 col-sm-6 text-center">
                                    <h1 class="py-3"> Děkujeme </h1>
                                    <div class="color-balls"></div>
                                    <h3>Vše proběhlo v pořádku !</h3>
                                </div>
                                <div class="d-none d-sm-block col-sm-3">
                                    <lottie class="img-fluid"
                                            :options="lottieIconFinal"
                                            :height="150"
                                            :width="150"/>
                                </div>
                            </div>
                            <p class="text-justify">
                                Nezávazný formulář byl správně vyplněn a nyní <strong>zpracováváme váš
                                požadavek</strong> na
                                cenovou nabídku. Ještě <strong>proběhne ověření správnosti</strong> vámi poskytnutých
                                údajů a
                                následně vaši poptávku předáme našim prověřeným poskytovatelům a specialistům v
                                oboru. Zpravidla zavoláme následující pracovní den, ale pokud jste zadali požadavek
                                dnes během pracovní doby, <strong>kontaktujeme vás</strong> nejspíš <strong>během
                                hodiny</strong>.
                            </p>
                            <p class="text-justify">
                                <small>
                                    Upozorňujeme, že nemusíte dostat cenovou nabídku, pokud: Specifikace uvedené
                                ve formuláři neodpovídají produktům/službám poskytovatele. V uvedených
                                kontaktních údajích je chyba, například neplatné telefonní číslo.
                                </small>
                            </p>
                            <hr>
                            <h3 class="py-3 text-center">FAQ</h3>
                            <div v-for="(item, index) in faq" :key="index">
                                <b-card no-body class="mb-1">
                                    <b-card-header header-tag="header" class="p-0" role="tab">
                                        <b-button block v-b-toggle="'accordion-'+index" variant="primary">{{item.title}}
                                        </b-button>
                                    </b-card-header>
                                    <b-collapse :visible="index === 0" :id="'accordion-'+index" accordion="my-accordion"
                                                role="tabpanel">
                                        <b-card-body>
                                            <b-card-text>
                                                {{item.text}}
                                            </b-card-text>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Lottie from "vue-lottie"
    import lottieDoneFinal from "../assets/lottie/50465-done";


    export default {

        components: {
            Lottie
        },
        name: "Finish",
        data: () => ({
            lottieIconFinal: {animationData: lottieDoneFinal, loop: false},
            faq: [
                {
                    title: 'Proč potřebujete mé údaje a nezobrazíte mi nabídku ihned?',
                    text: 'Abychom vám mohli nabídnout řešení přesně dle vašich požadavků na míru. Každý zákazník\n' +
                        'má jiné požadavky a také jinou představu. Proto je nutné vše vzít do úvahy v kalkulaci ceny.'
                },
                {
                    title: 'Jak zabezpečíte mé údaje?',
                    text: 'Bezpečnost vašich údajů je pro nás absolutní prioritou a nebereme ho na lehkou váhu. Vaše\n' +
                        'osobní údaje v rámci GDPR předáme pouze našim prověřeným partnerům, se kterými vás s\n' +
                        'vaším souhlasem spojíme, aby vám nabídli požadovanou službu/produkt. Řídíme se\n' +
                        'zásadami pro uchovávání údajů, které jasně stanovují, jak dlouhou budeme vaše údaje\n' +
                        'uchovávat. Vaše údaje budeme uchovávat jen po dobu nezbytně nutnou k zajištění\n' +
                        'služeb/produktů, o které máte zájem.'
                }
                /* {
                     title: 'Proč se mi nezobrazí rovnou ceník?  ',
                     'text': 'Ceny se mohou lišit v závislosti na vašich geografických podmínkách, specifických požadavcích a aktuálních kapacitách potencionálních poskytovatelů. Co nejdříve vás kontaktují naši spolehliví partneři, kteří vám nabídnou konkrétní cenovou nabídku. '
                 },
                 {
                     title: 'Proč potřebujete mé údaje? ',
                     'text': 'Abychom vám mohli nabídnout řešení přesně dle vašich požadavků. Protože není žádné základní řešení a vše je potřeba vzít do úvahy v kalkulaci ceny. Proto je potřeba odpovědět na pár základních otázek, a pak se vám ozveme s konkrétní nabídkou na vámi požadovaný produkt. '
                 },
                 {
                     title: 'Jak zabezpečíte mé údaje?  ',
                     'text': 'Bezpečnost vašich údajů je pro nás absolutní prioritou a nebereme ho na lehkou váhu. Vaše osobní údaje v rámci GDPR předáme pouze našim prověřeným partnerům, se kterými vás s vaším souhlasem spojíme, aby vám nabídli požadovanou službu/produkt. Řídíme se zásadami pro uchovávání údajů, které jasně stanovují, jak dlouhou budeme vaše údaje uchovávat. Vaše údaje budeme uchovávat jen po dobu nezbytně nutnou k zajištění služeb/produktů, o které máte zájem.  '
                 },
                 {
                     title: 'Odkud se bere náš profit ?  ',
                     'text': 'Když odešlete poptávku tak vás propojíme s příslušnými poskytovateli, tam je naše přidaná hodnota a ti nám zaplatí za propojení malou provizi. '
                 },*/
            ]
        })
    }
</script>

<style scoped>

</style>