<template>
  <div>

    <!-- <div v-for="(schema, index) in questions" :key="index">
         <div>index: {{index}}</div>
         <div v-if="isLastStep()" class="alert alert-danger">tadáááá</div>
         <vue-form-generator :schema="schema"
                             :model="model"
                             :options="formOptions"
                             ref="tabForm"
         >
         </vue-form-generator>
         <hr>
     </div>-->
    <!--
    <button @click="getActiveTabIndex()">aaaaa</button>
    <button v-if="activeStep === 0 && !isLastStep()"
            @click="nextStep()" class="wizard-footer-right"
    >Získej cenovou nabídku zdarma
    </button>
    <button v-else-if="activeStep > 0 && !isLastStep()"
            @click="nextStep()" class="wizard-footer-right"
    >Další
    </button>
    <button v-else @click="onComplete()" class="wizard-footer-right finish-button"> Získej cenovou nabídku zdarma
        xxxx dokončení
    </button>
-->
    <div id="conversion-value" class="d-none">10</div>
    <div class="text-center">
      <h3>Solární elektrárna s dotací</h3>
      <i>Cenová nabídka na míru vaší nemovitosti</i>
      <!--<div class="color-balls"></div>-->
    </div>
    <form-wizard
        title=""
        subtitle=""
        color="#70C95A"
        ref="formWizard"
    >

      <wizard-step
          slot-scope="props"
          slot="step"
          :tab="props.tab"
          :transition="props.transition"
          :index="props.index">
        <div slot="default">
          <i class="wizard-icon">
            <lottie v-if="getActiveTabIndex() > props.index"
                    :options="lottieIconDone"
                    :height="65"
                    :width="65"/>
          </i>
        </div>
        <div slot="active-step" style="display: flex; justify-content:center; align-items: center;">
          <div v-for="(schema, index) in questions" :key="index">
            <lottie v-if="props.index == index"
                    :options="lottieIcons[index]"
                    :height="65"
                    :width="65"/>
          </div>
        </div>
      </wizard-step>
      <tab-content v-for="(schema, index) in questions" :key="index"
                   title="" :before-change="validateTab"
                   :route="'/krok-'+(index +1)"
      >
        <b-alert show variant="info" v-if="schema.infoBefore">{{ schema.infoBefore }}</b-alert>
        <!--<div v-for="(schema, index) in questions" :key="index"></div>-->
        <vue-form-generator :schema="schema"
                            :model="model"
                            :options="formOptions"
                            :ref="'tabForm'+index"
        >
        </vue-form-generator>
        <b-alert show variant="info" v-if="schema.infoAfter">{{ schema.infoAfter }}</b-alert>
      </tab-content>


      <!--
      <tab-content title="" :before-change="validateTab2">
          <vue-form-generator :schema="questions.step2"
                              :model="model"
                              :options="formOptions"
                              ref="tabForm2"
          >
          </vue-form-generator>
      </tab-content>
      <tab-content title="" :before-change="validateTab3">
          <vue-form-generator :schema="questions.step3"
                              :model="model"
                              :options="formOptions"
                              ref="tabForm3"
          >
          </vue-form-generator>
      </tab-content>
      <tab-content title="" :before-change="validateTab4">
          <vue-form-generator :schema="questions.step4"
                              :model="model"
                              :options="formOptions"
                              ref="tabForm4">
          </vue-form-generator>
      </tab-content>
      <tab-content title="" :before-change="validateTab5">
          <vue-form-generator :schema="questions.step5"
                              :model="model"
                              :options="formOptions"
                              ref="tabForm5">
          </vue-form-generator>
      </tab-content>
      <tab-content title="" :before-change="validateTab6">
          <vue-form-generator :schema="questions.step6"
                              :model="model"
                              :options="formOptions"
                              ref="tabForm6">
          </vue-form-generator>
      </tab-content>
      <tab-content title="" :before-change="validateTab7">
          <vue-form-generator :schema="questions.step7"
                              :model="model"
                              :options="formOptions"
                              ref="tabForm7">
          </vue-form-generator>
      </tab-content>
      <tab-content title="" :before-change="validateTab8">
          <vue-form-generator :schema="questions.step8"
                              :model="model"
                              :options="formOptions"
                              ref="tabForm8">
          </vue-form-generator>
      </tab-content>
-->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-right">
          <wizard-button v-if="props.activeTabIndex === 0 && !props.isLastStep"
                         @click.native="props.nextTab()" class="myButton "
                         :style="props.fillButtonStyle">Získej cenovou nabídku zdarma
          </wizard-button>
          <wizard-button v-else-if="props.activeTabIndex > 0 && !props.isLastStep"
                         @click.native="props.nextTab()" class="wizard-footer-right myButton"
                         :style="props.fillButtonStyle">Další
          </wizard-button>
          <wizard-button v-else @click.native="onComplete()" class="wizard-footer-right myButton"
                         :style="props.fillButtonStyle"> Získej nabídku zdarma
          </wizard-button>
        </div>
        <div class="wizard-footer-left">
          <wizard-button v-if="props.activeTabIndex > 0" @click.native="props.prevTab()"
                         class="myButton"
                         :style="props.fillButtonStyle">Zpět
          </wizard-button>
        </div>
      </template>

    </form-wizard>
    <hr>
    <p class="text-center m-0 p-0">
      "Vyplňte krátký formulář, zabere to jen minutku a získejte cenovou nabídku
      ZDARMA<br>
      nebo nám na sebe zanechte kontakt zde: <router-link :to="{name:'Demand'}">Kotaktní formulář</router-link>"
    </p>

  </div>
</template>

<script>
import {api, questions} from "../config";
import axios from "axios"
import Lottie from "vue-lottie"
import lottiePhone from "../assets/lottie/43163-phone-accepted"
import lottieHome from "../assets/lottie/1342-location"
import lottieDoneBlock from '../assets/lottie/676-done'
import lottieDone from '../assets/lottie/972-done'
import lottieMail from '../assets/lottie/4541-mail-verification'
import lottieName from '../assets/lottie/39141-person-profile'

export default {
  name: 'WizardForm',
  created() {
    this.$root.$refs.WizardForm = this;
  },
  components: {
    Lottie
  },
  data: () => ({
    lottieIconDone: {animationData: lottieDone, loop: false},
    lottieIcons: [
      {animationData: lottieDoneBlock, loop: false},
      {animationData: lottieDoneBlock, loop: false},
      {animationData: lottieDoneBlock, loop: false},
      {animationData: lottieDoneBlock, loop: false},

      {animationData: lottieHome, loop: false},
      {animationData: lottieMail, loop: false},

      {animationData: lottieName, loop: false},
      {animationData: lottiePhone, loop: false},
    ],
    questions: questions,
    activeStep: 0,
    model: {
      name: '',
      phone: '',
      email: '',
      locality: '',
      question1: '',
      question2: '',
      question3: '',
      question4: '',
    },
    formOptions: {
      validateAfterLoad: false,
      validateAfterChanged: false,
      validateAsync: false
    }
  }),
  methods: {
    nextStep: function () {
      this.$refs.formWizard.nextTab();
    },
    isLastStep: function () {
      var lastStep = parseInt(Object.keys(questions)[Object.keys(questions).length - 1]);
      if (lastStep === this.activeStep) {
        return true;
      } else {
        return false;
      }
    },
    getActiveTabIndex: function () {
      if (typeof this.$refs.formWizard !== "undefined") {
        return this.$refs.formWizard.$data.activeTabIndex;
      } else {
        return 0;
      }
    },
    validateTab: function () {
      let currentIndex = this.$refs.formWizard.activeTabIndex;
      return this.$refs['tabForm' + currentIndex][0].validate();
    },
    onComplete: function () {
      if (!this.validateTab()) {
        return false;
      }
      let apiUrl = '';
      let token = '';
      if (process.env.NODE_ENV === 'development') {
        apiUrl = api.urlDev;
        token = 'test'
      } else {
        apiUrl = api.urlProduction;
        token = api.token;
      }

      let gaId = this.$cookies.get("_ga");
      if (gaId.length) {
        gaId = gaId.replace(/^GA\d+\.\d+\./, '');
      }
      let fbclid = this.$cookies.get("fbclid");

      let postData = {
        "secret": token,
        "data": {
          "name": this.model.name,
          "phone": this.model.phone,
          "email": this.model.email,
          "ga_id": gaId,
          "fbclid": fbclid,
          "note":
              "Lokalita:" + this.model.locality + "; " +
              "Objekt: " + this.model.question1 + "; " +
              "Zálohy: " + this.model.question2 + "; " +
              "Akumulace: " + this.model.question3 + "; "
        }
      };
      let headers = {
        "Content-Type": "application/json",
        'X-Requested-With': 'XMLHttpRequest'
      };
      let self = this;
      axios.post(apiUrl, postData, {headers})
          .then(function (response) {
            if (response.status === 200) {
              let responseData = response.data;
              if (responseData.status === 'ok') {
                self.$gtm.trackEvent({
                  event: "form_sent",
                  result: "ok"
                });
                self.$router.push({name: 'Finish'});
              } else {
                self.$gtm.trackEvent({
                  event: "form_sent",
                  result: "api-err-problem"
                });
                //console.log(response);
                self.$router.push({name: 'Finish'});
              }
            } else {
              self.$gtm.trackEvent({
                event: "form_sent",
                result: "api-err-bad_response"
              });
              alert('Oops, nastala chyba. Prosím kontaktujde správce systému na admin@mamzajem.cz. Děkujeme.')
            }
          }).catch(function (error) {
        self.$gtm.trackEvent({
          event: "form_sent",
          result: "api-err-try_catch"
        });
        console.log(error);
        alert('Oops, nastala chyba. Prosím kontaktujde správce systému na admin@mamzajem.cz. Děkujeme.')
      });
    }
  }
}
</script>

<style scoped>

</style>