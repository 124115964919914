<template>
    <div id="app">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <nav>
                        <div id="nav">
                            <h1 class="logo">
                                <router-link to="/">{{host}}</router-link>
                            </h1>
                            <!-- <router-link to="/">Home</router-link>
                             |
                             <router-link :to="{name:'Finish'}">Finish page</router-link>
                             |-->
                        </div>
                    </nav>
                    <header>

                    </header>
                </div>
            </div>
        </div>

        <main>
            <router-view/>
        </main>
        <footer>
            <div class="text-center py-3">
                <p class="p-0 m-0">
                    ©{{new Date().getFullYear()}}
                    <router-link to="/">{{host}}</router-link>
                    | <router-link :to="{name:'Gdpr'}">Ochrana os. údajů</router-link>
                   | Provozovatel: Kaulike s.r.o. IČ 09546618 se sídlem Příkop 843/4
                  Brno 602 00 vedená u Krajského soudu v Brně C 119526
                </p>
            </div>
        </footer>
    </div>
</template>
<script>
    export default {
        data: () => ({
            host: location.host
        })
    }
</script>
<style lang="scss">
</style>
