import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PageNotFound from '../views/PageNotFound'
import Gdpr from "../views/Gdpr";
import Finish from "../views/Finish";
import Demand from "../views/Demand";
import {questions} from "../config";

/** Odstraní: NavigationDuplicated: Avoided redundant navigation to current location: vue form wizard **/
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        //alias:'/krok-*',
        name: 'Home',
        component: Home
    },
    {
        path: '/finish',
        name: 'Finish',
        component: Finish
    },
    {
        path: '/poptavka',
        name: 'Demand',
        component: Demand
    }, {
        path: '/gdpr',
        name: 'Gdpr',
        component: Gdpr
    },
    {
        path: '/404',
        name: 'PageNotFoud',
        component: PageNotFound,
    }, {
        path: '*',
        redirect: '/404'
    }
];
questions.forEach(function (value, index) {
    routes.push({
            path: '/krok-' + (index + 1),
            name: 'Krok-' + (index + 1),
            component: Home
        }
    );
});

const router = new VueRouter({
    routes,
    mode: 'history',
});

function hasQueryParams(route) {
    return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
    document.title = process.env.VUE_APP_TITLE + ' | ' + to.name;
    if (!hasQueryParams(to) && hasQueryParams(from)) {
        next({name: to.name, query: from.query});
    } else {
        next()
    }
})

export default router
